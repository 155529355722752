import { localconsole } from "../../App"
import { Comp, CompDictionary, getCompReference } from "./comp"
import { Ship, ShipDictionary } from "./ship"
import { ColorDictionary, KVProps, OptionDictionary } from "./types"


export interface ProjectBuilder {
    name: string,
    version?: number,
    existingComponentIds: number[],
    components: CompDictionary,
    existingShipIds: number[],
    ships: ShipDictionary,
    options: OptionDictionary,
    colors: ColorDictionary,
}

export const newProjectBuilder: ProjectBuilder = {
    name: "",
    version: 0,
    existingComponentIds: [] as number[],
    components: {} as CompDictionary, //e.g. 1: Table
    existingShipIds: [] as number[],
    ships: {} as ShipDictionary, //e.g. 1: Ship
    options: {
        "Legend": false,
        "Orthogonal": false,
        "Fields": true,
        //"Gantt": false,
    } as OptionDictionary,
    colors: {
        "Background": "#8e3d67"
    },
}

export function projectBuilderToString(pb: ProjectBuilder | null, spaces?: number) {
    localconsole("ENTER: projectBuilderToString")
    //localconsole(code)
    let code = pb ?? newProjectBuilder;
    //remove the ids from the objects
    let displayCode = {
        ...{
            ...code,
            components : Object.entries<Comp>(code.components).map(([i, compInstance]) => { return {...compInstance, id: undefined, columns: compInstance.columns.map((column) => {return {...column, id:undefined}})} }),
            ships: Object.entries<Ship>(code.ships).map(([i, shipInstance]) => { return {...shipInstance, id:undefined} })
        },
    }
    //localconsole("exiting")
    return JSON.stringify(displayCode, null, spaces ?? 2)
}

// this will throw, which is used by builder to set JSONValid value to indicate when there is an issue in converting
export function restructureState(jsonString: string) {
    localconsole("ENTER: restructureState")
    let tree = JSON.parse(jsonString)
    
    localconsole(".restructureState: JSON parsed, transforming array if needed...")
    //transform array to object
    let comp: CompDictionary = {}
    let ship: ShipDictionary = {}
    let options: OptionDictionary = {}
    let colors: ColorDictionary = {}
    let compIds: number[] = [];
    let shipIds: number[] = [];
    if (Array.isArray(tree.components)) {
        tree.components.forEach((c: Comp, i: number) => {
            let inc = (i + 1).toString();
            comp[inc] = {...c, id: i + 1};
            let colInc = 1;
            comp[inc].columns.forEach((column) => {
                column.id = colInc
                colInc = colInc + 1
            })
        })
        compIds = tree.components.map((c: object, i: number) => i + 1)
    } else {
        if (typeof tree.components === "object") {
            comp = tree.components
            compIds = Object.keys(tree.components).map((c: string, i: number) => i + 1)
        }
    }

    
    localconsole(".restructureState: Checking for relationships attribute")
    if (tree.relationships && !tree.ships) {
        tree.ships = tree.relationships
    }
    if (Array.isArray(tree.ships)) {
        tree.ships.forEach((s: Ship, i: number) => {
            let inc = (i + 1).toString();
            ship[inc] = {...s, id: i + 1}
        })
        shipIds = tree.ships.map((s: object, i: number) => i + 1)
    } else {
        if (typeof tree.ships === "object") {
            ship = tree.ships
            shipIds = Object.keys(tree.ships).map((c: string, i: number) => i + 1)
        }
    }
    localconsole(".restructureState: Checking for options attribute")
    for (let o in tree.options) {
        options[o] = !!tree.options[o]
    }
    localconsole(".restructureState: Checking for colors attribute")
    for (let o in tree.colors) {
        colors[o] = tree.colors[o]
    }
    let restructured: ProjectBuilder = {
        ...newProjectBuilder,
        name: tree.name,
        existingComponentIds:  compIds,
        components: comp,
        existingShipIds: shipIds,
        ships: ship,
        options: options,
        colors: colors
    }
    localconsole(".restructureState: Restructured")
    localconsole(restructured)
    return restructured;
}


