import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface SVGSlice {
    svg: string
}

export const sliceInitial: SVGSlice = {
    svg: "",
}


//using normalised state shape with Ids in objects instead of arrays as it's quite natural for a database model!
export const svgSlice = createSlice({
    name: 'svg',
    initialState: sliceInitial,
    reducers: {
        newSVG: (state, action: PayloadAction<string>) => {
            state.svg = action.payload
        }
    }
})

export const selectSVG = (state: RootState) => state.svg.svg

export const {
    newSVG
} = svgSlice.actions

export default svgSlice.reducer