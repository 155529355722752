import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface UISlice {
    isShowAssembly: boolean
}

export const sliceInitial: UISlice = {
    isShowAssembly: false,
}


//using normalised state shape with Ids in objects instead of arrays as it's quite natural for a database model!
export const uiSlice = createSlice({
    name: 'ui',
    initialState: sliceInitial,
    reducers: {
        toggleShowAssembly: (state) => {
            state.isShowAssembly = !state.isShowAssembly
        }
    }
})

export const selectShowAssembly = (state: RootState) => state.ui.isShowAssembly

export const {
    toggleShowAssembly
} = uiSlice.actions

export default uiSlice.reducer