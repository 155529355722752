//TODO 20241210 - get these from elsewhere. import {Ship, ShipFieldsDictionary} from "../state/slices/diagramSlice";
import React from "react";
import { Ship, ShipFieldsDictionary } from "../state/objects/ship";


interface ShipsInputProps {
    record: Ship,
    field: ShipFieldsDictionary,
    setRecord: (record: Ship)=>void,
    activateRecord: (id: number)=>void
}

const ShipsInput = (props: ShipsInputProps) => {
    let fieldVal: string;
    switch(props.field) {
        case ShipFieldsDictionary.from: { fieldVal = props.record.from ?? ""; break; }
        case ShipFieldsDictionary.to: { fieldVal = props.record.to ?? ""; break; }
        case ShipFieldsDictionary.cardinality: { fieldVal = props.record.cardinality ?? ""; break; }
        case ShipFieldsDictionary.fromCol: { fieldVal = props.record.from_col ?? ""; break; }
        case ShipFieldsDictionary.toCol: { fieldVal = props.record.to_col ?? ""; break; }
        case ShipFieldsDictionary.freestyle: { fieldVal = props.record.freestyle ?? ""; break; }
        default: { fieldVal = ""; break; }
    }
    const setFieldVal = (newFieldVal: string) => {
        let newRecord = {
            ...props.record
        }
        switch(props.field) {
            case ShipFieldsDictionary.from: { newRecord.from = newFieldVal; break; }
            case ShipFieldsDictionary.to: { newRecord.to = newFieldVal; break; }
            case ShipFieldsDictionary.cardinality: { newRecord.cardinality = newFieldVal; break; }
            case ShipFieldsDictionary.fromCol: { newRecord.from_col = newFieldVal; break; }
            case ShipFieldsDictionary.toCol: { newRecord.to_col = newFieldVal; break; }
            case ShipFieldsDictionary.freestyle: { newRecord.freestyle = newFieldVal; break; }
            default: { break; }
        }
        props.setRecord(newRecord)
    }

    return (
        <input className="input"
               type="text"
               //placeholder={props.field}
               //onClick={(e) => e.stopPropagation(); props.setActiveComp(props.id);}
               onChange={(e) => { setFieldVal(e.currentTarget.value) } }
               value={fieldVal}
               onClick={(e) => {
                   //highlight record but don't toggle on Inputs, to deselect the row itself should be clicked
                   props.activateRecord(props.record.id);
                   e.stopPropagation();
               }}
        >
        </input>
    )
}

export default ShipsInput