import React, {useState} from "react";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faUpload} from "@fortawesome/free-solid-svg-icons";
//TODO 20241210 - get these from elsewhere. import {DiagramSlice, replaceSlice, stateToString} from "../state/slices/diagramSlice";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {selectCurrentProject, newProject, selectCurrentProjectName, selectProjects, updateProject, versionIncrement} from "../state/slices/systemSlice";
import {localconsole} from "../App";
//import { createSelector } from "@reduxjs/toolkit";
import { newProjectBuilder, ProjectBuilder, projectBuilderToString, restructureState } from "../state/objects/projectBuilder";
import { selectSVG } from "../state/slices/svgSlice";

/*interface MenuProps {
    formsVisible: boolean,
    setFormsVisible: any
}*/

function Menu() {
    //Todo - move to state slice and persist in localStorage so it's not shown on every page refresh
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    //const currentState = useAppSelector(state => state.diagram)
    //const diagramSVG = useAppSelector(state => state.diagram.diagramSVG)
    
    const currentProject = useAppSelector(state => selectCurrentProject(state));
    const currentProjectName = useAppSelector(state => selectCurrentProjectName(state))
    const diagramSVG = useAppSelector(state => selectSVG(state))
    const version = currentProject.version ?? 0
    const dispatch = useAppDispatch()

    const restoreState = (fileList : FileList | null) => {
        if ( fileList != null ) {
            let file = fileList[0]
            let r = new FileReader();
            r.onload = (function (file) {
                return function (e) {
                    if (e.target) {
                        let contents = e.target.result;
                        if ( contents ) {
                            //console.log(`Restoring ${file.name}`)

                            {/*TODO - use functions from builder to remove extraneous state attributes*/}

                            //if it's an SVG, get the json
                            if (file.name.split('.').pop()?.toLowerCase() === "svg") {
                                const pattern = "<!--Chris_Barsby (.*?)-->"
                                const matches = contents.toString().match(pattern);

                                if (matches && matches.length === 2) {
                                    //grab the glob match
                                    contents = matches[1]
                                } else {
                                    console.log(`Invalid svg, ${matches?.length}, cannot load`)
                                }

                            }

                            let newStateTree = contents.toString()

                            //setJsonStateTree(newStateTree)
                            try {
                                let restructured: ProjectBuilder = restructureState(newStateTree);
                                if ( restructured.options.Fields === undefined ) {
                                    restructured.options.Fields = true
                                }
                                let split_name = file.name.split(".")
                                let file_name = ( split_name.length > 1) ? split_name[0] : file.name;
                                localconsole(`Set project name to ${file_name}`)
                                dispatch(newProject({
                                    ...restructured,
                                    name: file_name,
                                }));
                                localconsole(`Update state ${restructured}`)
                                //dispatch(updateProject(restructured))

                            } catch(e) {
                                //setJsonValid(((jsonValid<6)?jsonValid + 1:6))
                                console.log("Invalid file, cannot load")
                            }
                        }
                    }
                };
            })(file);
            r.readAsText(file);
        }
    }

    const downloadSVG = () => {
        //console.log(`Downloading ${diagramSVG}`)

        /*fetch(svg_url)
            .then((res) => {
                return res.text();
            })
            .then((data) => {*/
                //console.log("data", data)
                //let rando_key = Math.random().toString().substring(2,5);
                let elem = document.createElement("a");
                elem.setAttribute("href", "data:text/plain," + diagramSVG);
                elem.setAttribute("type", "image/svg+xml");
                elem.setAttribute("download", `${currentProjectName === "" ? "berd" : currentProject}_v${version}.svg`);
                elem.style.display = "none";
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);

                //SVG now contains the state tree, don't need this
                //aaand shortly thereafter download the state tree
                /*setTimeout(() => {
                    let data = stateToString(currentState);
                    console.log(data)
                    let elem = document.createElement("a");
                    elem.setAttribute("href", "data:text/plain," + encodeURIComponent(data));
                    elem.setAttribute("type", "text/plain");
                    elem.setAttribute("download", `berd_${rando_key}.json`);
                    elem.style.display = "none";
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }, 1000)*/

            /*})
            .catch((error) => {
                console.log("Error getting SVG", error)
            })*/
    }

    return (
        <div>
        <nav className="navbar is-transparent is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">

                <a href="/" className={"navbar-item"}>
                    <div className={"subtitle is-family-sans-serif has-text-weight-bold has-text-primary"}>berd</div>
                </a>

                <div role="button"
                   className={"navbar-burger" + ((menuOpen)?" is-active":"")}
                   onClick={() => setMenuOpen(!menuOpen)}
                   aria-label="menu"
                   aria-expanded="false"
                   data-target="berd_menu"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>


            <div id={"berd_menu"} className={"navbar-menu" + ((menuOpen)?" is-active":"")}
            onClick={() => setMenuOpen(false)}
            >
                <div className="navbar-start">

                    {/* TODO - Can I use a Link from react router to avoid full page reload? */}
                    <Link to={"/"} className={"navbar-item"}>Home</Link>
                    {/*<a className="navbar-item" href={"/"}>
                        Home
                    </a>*/}


                    <Link to={"/builder"} className={"navbar-item"}>Builder</Link>
                    {/*<a className="navbar-item" href={"/builder"}>
                        Builder
                    </a>*/}

                    <div className={"navbar-item has-dropdown is-hoverable"}> {/* + ((props.formsVisible)?" has-dropdown-up":"") */}


                        <div className="navbar-link" /* onClick={() => { props.setFormsVisible(!props.formsVisible) ; setFirstTime(false) }}*/ >
                             {/*Poor mans app tour*/}
                            Help
                            {/*{(firstTime)?<span className={"pulse pulsecorrection"}/>:<div></div>}*/}
                        </div>

                        <div className="navbar-dropdown">
                            {/*<a className="navbar-item">
                                Integration
                            </a>
                            <a className="navbar-item">
                                Examples
                            </a>*/}
                            <Link to={"/faq"} className={"navbar-item"}>FAQs</Link>
                            <Link to={"/examples"} className={"navbar-item"}>Examples</Link>


                        </div>
                    </div>




                </div>


                <div className="navbar-end">

                    <div className={"navbar-item"}>



                            <label className={"button is-fullwidth"} htmlFor={"schema_upload"}>
                                                        <span className={"icon"}>
                                                            <FontAwesomeIcon
                                                                icon={faUpload}/>
                                                        </span>
                                <span>Restore</span>
                            </label>
                            <input id={"schema_upload"} className={"is-hidden"} type={"file"}
                                   accept={".json, .svg"}
                                   onChange={(e) => restoreState(e.currentTarget.files)}
                            >
                            </input>



                    </div>

                    <div className={"navbar-item"}>


                        <button className={"button is-fullwidth"} onClick={() => {
                            dispatch(versionIncrement())
                            downloadSVG()
                        }}>
                                <span className={"icon"}>
                                    <FontAwesomeIcon icon={faDownload}/>
                                </span>
                            <span>Download</span>
                        </button>


                    </div>

                    <a className="navbar-item pr-5" href="https://www.linkedin.com/in/barsby" target={"_blank"}
                       rel="noreferrer">
                        <img src="cb.png" alt="berd"></img>
                    </a>

                </div>
            </div>
        </nav>
        </div>
    );
}

export default Menu;