//TODO 20241210 - get these from elsewhere. import {Column, ColumnFieldsDictionary} from "../state/slices/diagramSlice";
import React from "react";
import { Column, ColumnFieldsDictionary } from "../state/objects/comp";


interface CompsFieldInputProps {
    record: Column,
    field: ColumnFieldsDictionary,
    setRecord: (record: Column)=>void,
}
const CompsFieldInput = (props: CompsFieldInputProps) => {
    let fieldVal;
    switch(props.field) {
        case ColumnFieldsDictionary.name: { fieldVal = props.record.name; break; }
        case ColumnFieldsDictionary.db_name: { fieldVal = props.record.db_name; break; }
        default: { fieldVal = ""; break; }
    }
    const setFieldVal = (newFieldVal: string) => {

        let newRecord = {
            ...props.record
        }
        switch(props.field) {
            case ColumnFieldsDictionary.name: { newRecord.name = newFieldVal; break; }
            case ColumnFieldsDictionary.db_name: { newRecord.db_name = newFieldVal; break; }
            default: { break; }
        }
        props.setRecord(newRecord)
    }

    return (
        <input className="input is-small"
               type="text"
               onChange={(e) => { setFieldVal(e.currentTarget.value) } }
               value={fieldVal}
        >
        </input>
    )
}

export default CompsFieldInput