import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { newProjectBuilder, ProjectBuilder } from '../objects/projectBuilder';
import { RootState } from '../store';
import { localconsole } from '../../App';
import { Comp, getCompReference } from '../objects/comp';
import { Ship } from '../objects/ship';
import { KVProps } from '../objects/types';

//TODO - add rename ability

export interface SystemSlice {
    existingProjectNames: string[],
    projects: ProjectBuilder[],
    currentProject: string,
}


export const sliceInitial: SystemSlice = {
    existingProjectNames: [] as string[],
    projects: [] as ProjectBuilder[],
    currentProject: ""
}


//using normalised state shape with Ids in objects instead of arrays as it's quite natural for a database model!
export const systemSlice = createSlice({
    name: 'system',
    initialState: sliceInitial,
    reducers: {
        newProject: (state, action: PayloadAction<ProjectBuilder>) => {
            localconsole("ENTER: systemSlice.reducer.newProject")
            localconsole(action.payload)
            let project = action.payload;
            project.version = project.version ?? 1;
            //update the project name before the projects to avoid Effects in Builder using currentProject instead of new one
            state.currentProject = project.name

            if (!state.existingProjectNames.includes(project.name) ) {
                state.existingProjectNames.push(project.name);
                state.projects.push(project);
            }
        },
        renameProject: (state, action: PayloadAction<{old_name: string, new_name: string}>) => {
            localconsole("ENTER: systemSlice.reducer.renameProject")
            let {old_name, new_name} = action.payload;
            

            if (!state.existingProjectNames.includes(new_name) ) {
                let pb = state.projects.filter((_pb) => _pb.name == old_name)[0]
                pb.name = new_name
                //update the project name before the projects to avoid Effects in Builder using currentProject instead of new one
                state.currentProject = new_name
                for ( let i = state.existingProjectNames.length; i > 0; i-- ) {
                    if ( state.existingProjectNames[i-1] === old_name ) {
                        state.existingProjectNames.splice(i-1, 1, new_name)
                        break
                    }
                }
            }
        },
        removeProject: (state, action: PayloadAction<string>) => {
            localconsole("ENTER: systemSlice.reducer.removeProject")
            let projectName = action.payload;
            //console.log(`"Removing ${project}`)
            //state.currentProject = ""
            for ( let i = state.existingProjectNames.length; i > 0; i-- ) {
                if ( state.existingProjectNames[i-1] === projectName ) {
                    state.existingProjectNames.splice(i-1, 1)
                    break
                }
            }
            for ( let i = state.projects.length; i > 0; i-- ) {
                if (state.projects[i-1].name === projectName) {
                    state.projects.splice(i-1, 1)
                    break
                }
            }
        },
        //changes the selected project, so just the name in the selection
        changeProject: (state, action: PayloadAction<string>) => {
            localconsole(`ENTER: systemSlice.reducer.changeProject: ${action.payload}`)
            state.currentProject = action.payload
        },
        //modify the contents of a project, that is, the diagram
        updateProject: (state, action: PayloadAction<ProjectBuilder>) => {
            localconsole("ENTER: systemSlice.reducer.updateProject")
            let project = {
                ...action.payload,
            }
            localconsole(1)
            localconsole(project)
            
            if (project.options.Fields === undefined) {
                project.options = {
                    ...project.options,
                    "Fields": true
                }
            }
            localconsole(2)
            localconsole(project)
            state.currentProject = project.name

            if (state.projects.length === 0) {
                state.projects.push(project);
            }


            for ( let i = state.projects.length; i > 0; i-- ) {
                if (state.projects[i-1].name === project.name) {
                    state.projects.splice(i-1, 1, project)
                } else if (i === 1) {
                    state.projects.push(project);
                }
            }
            
        },
        versionIncrement: (state) => {
            localconsole("ENTER: systemSlice.reducer.versionIncrement")
            for ( let i = state.projects.length; i > 0; i-- ) {
                if (state.projects[i-1].name === state.currentProject) {
                    state.projects.splice(i-1, 1, {
                        ...state.projects[i-1],
                        version: (state.projects[i-1].version ?? 0) + 1
                    })
                }
            }
        },


        sortComps: (state) => {
            localconsole("ENTER: systemSlice.reducer.sortComps")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
        
            if (pb.components && Object.keys(pb.components).length > 0){
        
                //converts dictionary to array, sorts it by component reference, remaps the key values by index, assigns back to state
                pb.components = Object.fromEntries(Object.entries<Comp>(pb.components).sort(
                    ([k1, c1], [k2, c2]) => {
                        //const [k1, c1] = a;
                        //const [k2, c2] = b;
                        return (
                            getCompReference(c1) ?? ""
                        ).localeCompare((getCompReference(c2) ?? ""))
                    }
                    ).map(
                        (kv: [string, Comp], i) => {
                            const [k, v] = kv;
                            v.id = i + 1
                            return [i + 1, v]
                        }
                )
                
            )
                pb.existingComponentIds = Object.entries<Comp>(pb.components).map(([k, c]) => c.id)
            }
        },
        
        
        sortShips: (state) => {
            localconsole("ENTER: systemSlice.reducer.sortShips")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            //converts dictionary to array, sorts it by relationship origin, remaps the key values by index, assigns back to state
            pb.ships = Object.fromEntries(Object.entries<Ship>(pb.ships).sort(([k1, s1], [k2, s2]) => {
                    return s1.from.localeCompare(s2.from)
                }).map(
                    ([k,v] ,i) => {
                        v.id = i + 1
                        return [i + 1, v]
                    }
            ))
              //localconsole(pb.ships)
              pb.existingShipIds = Object.entries<Ship>(pb.ships).map(([k, c]) => c.id)
              //localconsole(pb.existingShipIds)
        
              
        },
        
        
        //TODO, table isn't a good name anymore, should be something like upsertComp
        upsertTable : (state, action: PayloadAction<Comp>) => {
            localconsole("ENTER: systemSlice.reducer.upsertTable")
            let comp = action.payload
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            //use spread operator to gather existing components and then address specifically the one we're adding/updating
            if (!pb.existingComponentIds.includes(comp.id) ) {
                localconsole("Pushing to Ids...")
                pb.existingComponentIds.push(comp.id)
            }
            localconsole("Updating components...")
            pb.components = {
                ...pb.components,
                [comp.id]: comp
            }
            
        },
        
        upsertShip: (state, action: PayloadAction<Ship>) => {
            localconsole("ENTER: systemSlice.reducer.upsertShip")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let ship = action.payload;
            //localconsole(pb.ships)
            //localconsole(pb.existingShipIds)
            if ( !pb.existingShipIds.includes(ship.id) ) {
                pb.existingShipIds.push(ship.id)
            }
            //validate the entry for cardinality, 1:1 is just a line so is a catch all at the moment
            
            pb.ships = {
                ...pb.ships,
                [ship.id]: {
                    ...ship,
                    cardinality: (["1:M", "M:1", "ZoO", "ExO", "ZoM", "OoM", "DoT", "ArO", "---", "Cmp", "Agg", "Ext"].includes(ship.cardinality))?ship.cardinality:"1:1"
                }
            }
            
        },
        
        //TODO, table isn't a good name anymore, should be something like delComp
        delTable: (state, action: PayloadAction<number>) => {
            localconsole("ENTER: systemSlice.reducer.delTable")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let component_id = action.payload
            //the [key: number] bit specifies the type of the object that's in the components property so that it can be indexed and removed
            const newComponents: {[key: number] : Comp} = { ...pb.components };
            //remove a property from the object
            delete newComponents[component_id];
            pb.components = newComponents;
            pb.existingComponentIds = pb.existingComponentIds.filter((num: number) => num !== component_id)
            
        },
        
        //TODO, table isn't a good name anymore, should be something like copyComp
        copyTable: (state, action: PayloadAction<number>) => {
            localconsole("ENTER: systemSlice.reducer.copyTable")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let component_id = action.payload
        
            let component = {...pb.components[component_id]};
            const new_id = ( ( pb.existingComponentIds.length > 0 ) ? Math.max( ...pb.existingComponentIds, 0 ) + 1 : 1 )
            component.id = new_id
            if (!pb.existingComponentIds.includes(new_id) ) {
                pb.existingComponentIds.push(new_id)
            }
            pb.components = {
                ...pb.components,
                [new_id]: component
            }
            
        },
        
        
        toggleOption: (state, action: PayloadAction<string>) => {
            localconsole("ENTER: systemSlice.reducer.toggleOption")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let option_name = action.payload
            if (Object.keys(newProjectBuilder.options).includes(option_name)) {
                if (option_name === "Gantt" && window.location.hostname !== "localhost") {
                    //temporarily do not allow Gantt as I have not finished the code
                    console.log("Gantt is disabled until feature is complete")
                } else {
                    pb.options[option_name] = !(!!(pb.options[option_name]))
                }
            }
            
        },
        
        setColor: (state, action: PayloadAction<KVProps>) => {
            localconsole("ENTER: systemSlice.reducer.setColor")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let color = action.payload
            //console.log("Setting color: ")
            
            pb.colors[color.key] = color.value
            
        },
        
        delShip: (state, action: PayloadAction<number>) => {
            localconsole("ENTER: systemSlice.reducer.delShip")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let ship_id = action.payload
            //the [key: number] bit specifies the type of the object that's in the ships property so that it can be indexed and removed
            const newShips: {[key: number] : Ship} = { ...pb.ships };
            //remove a property from the object
            delete newShips[ship_id];
            pb.ships = newShips;
            pb.existingShipIds = pb.existingShipIds.filter((num: number) => num !== ship_id)
            
        },
        copyShip: (state, action: PayloadAction<number>) => {
            localconsole("ENTER: systemSlice.reducer.copyShip")
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let ship_id = action.payload
            
              let ship = {...pb.ships[ship_id]};
              const new_id = ( ( pb.existingShipIds.length > 0 ) ? Math.max( ...pb.existingShipIds, 0 ) + 1 : 1 )
              ship.id = new_id
            if (!pb.existingShipIds.includes(new_id) ) { pb.existingShipIds.push(new_id) }
            pb.ships = {
              ...pb.ships,
              [new_id]: ship
            }
            
        },
        removeCompTag: (state, action: PayloadAction<{component_id: number, tag: string}>) => {
            localconsole("ENTER: systemSlice.reducer.removeCompTag")
            let {component_id, tag} = action.payload
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let comp = {...pb.components[component_id]};
            comp.tags = comp.tags.filter(t => t != tag)
            pb.components = {
                ...pb.components,
                [comp.id]: comp
            }
        },
        tagComp: (state, action: PayloadAction<{component_id: number, tag: string}>) => {
            localconsole("ENTER: systemSlice.reducer.tagComp")
            let {component_id, tag} = action.payload
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let comp = {...pb.components[component_id]};
            if (comp.tags === undefined) {
                comp.tags = []
            }
            comp.tags.push(tag)
            pb.components = {
                ...pb.components,
                [comp.id]: comp
            }
        },
        removeShipTag: (state, action: PayloadAction<{ship_id: number, tag: string}>) => {
            localconsole("ENTER: systemSlice.reducer.removeShipTag")
            let {ship_id, tag} = action.payload
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let ship = {...pb.ships[ship_id]};
            ship.tags = ship.tags.filter(t => t != tag)
            pb.ships = {
                ...pb.ships,
                [ship.id]: ship
            }
        },
        tagShip: (state, action: PayloadAction<{ship_id: number, tag: string}>) => {
            localconsole("ENTER: systemSlice.reducer.tagShip")
            let {ship_id, tag} = action.payload
            let pb = state.projects.filter((_pb) => _pb.name == state.currentProject)[0]
            
            let ship = {...pb.ships[ship_id]};
            if (ship.tags === undefined) {
                ship.tags = []
            }
            ship.tags.push(tag)
            pb.ships = {
                ...pb.ships,
                [ship.id]: ship
            }
        },

    },
})

export const selectExistingProjectNames = (state: RootState) => state.system.existingProjectNames
export const selectProjects = (state: RootState) => state.system.projects
export const selectCurrentProjectName = (state: RootState) => state.system.currentProject
export const selectCurrentProject = createSelector(
    [selectProjects, selectCurrentProjectName],
    (projects: ProjectBuilder[], currentProjectName: string) => {
      return projects.find(project => project.name === currentProjectName) ?? newProjectBuilder;
    }
  );
export const selectCurrentProjectShips = createSelector(
    [selectCurrentProject],
    (project: ProjectBuilder) => {
        return project.ships
    }
);
export const selectCurrentProjectShipIds = createSelector(
    [selectCurrentProject],
    (project: ProjectBuilder) => {
        return project.existingShipIds
    }
);
export const selectCurrentProjectComponents = createSelector(
    [selectCurrentProject],
    (project: ProjectBuilder) => {
        return project.components
    }
);

export const {
    newProject,
    removeProject,
    changeProject,
    updateProject,
    versionIncrement,
    sortComps,
    sortShips,
    upsertTable,
    upsertShip,
    delTable,
    delShip,
    copyTable,
    copyShip,
    toggleOption,
    setColor,
    removeCompTag,
    tagComp,
    renameProject,
    removeShipTag,
    tagShip,
} = systemSlice.actions

export default systemSlice.reducer


