//fontawesome diagram-project

import React, {useEffect, useState} from "react";
import Diagram from "./diagram";
import Comps from "./comps";
import Ships from "./relationships";
import Options, { ItemProps } from "./options";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode, faTable, faTrash, faUpload, faPlus, faArrowDownAZ, faDiagramProject, faPen, faPlusMinus, faCopy} from "@fortawesome/free-solid-svg-icons";
import {
    Comp,
    CompDictionary,
    newComp,
    
} from "../state/objects/comp";
import {
    newShip,
    Ship,
    ShipDictionary,
} from "../state/objects/ship";
import { OptionDictionary, ColorDictionary } from "../state/objects/types";
import { newProject, removeProject, changeProject, updateProject, selectProjects, selectCurrentProjectName, selectExistingProjectNames, sortComps, sortShips, renameProject, selectCurrentProject, toggleOption, setColor } from "../state/slices/systemSlice"
import JSONBuilder from "./JSONBuilder";
import {localconsole} from "../App";
import { newProjectBuilder, ProjectBuilder, projectBuilderToString, restructureState, } from "../state/objects/projectBuilder";
//import { createSelector, current } from "@reduxjs/toolkit";
import { newSVG } from "../state/slices/svgSlice";
import { addProjectToAssembly, addTagToCurrentAssembly, changeAssembly, newAssembly, removeAssembly, removeProjectFromAssembly, removeTagFromAssembly, renameAssembly, selectAssemblies, selectCurrentAssembly, selectExistingAssemblyNames, setAssemblyColor, toggleAssemblyOption, toggleTagInCurrentAssemly, toggleUnTagged } from "../state/slices/assemblySlice";
import { newAssemblyObject } from "../state/objects/assembly";
import { selectShowAssembly, toggleShowAssembly } from "../state/slices/uiSlice";

//TODO - refactor the Tabs to show all or nothing based on active Tab. Allow for different Tabs and sort out defaults etc, with introduction of Gantt this is getting messy
// Getting confused when using option to display the Component builder vs Events



function Builder() {
    //const existingProjectIds = useAppSelector(state => state.project.existingProjectIds)
    const existingProjectNames = useAppSelector(state => selectExistingProjectNames(state))
    const availableProjects = useAppSelector(state => selectProjects(state))
    const currentProject = useAppSelector(selectCurrentProject);
    
    const existingAssemblyNames = useAppSelector(state => selectExistingAssemblyNames(state))
    const availableAssemblies = useAppSelector(state => selectAssemblies(state))
    const currentAssembly = useAppSelector(selectCurrentAssembly);
    
    const isShowAssembly = useAppSelector(state => selectShowAssembly(state))

    //localconsole("builder: Current Assembly")
    //localconsole(currentAssembly)

    const existingComponentIds = currentProject?.existingComponentIds
    const components = currentProject?.components
    const existingShipIds = currentProject?.existingShipIds
    const ships = currentProject?.ships
    //const diagramStateTree = currentProject
    const diagramOptions = currentProject?.options

    const dispatch = useAppDispatch()

    const [formsVisible, setFormsVisible] = useState<boolean>(true);
    const [activeComp, setActiveComp] = useState<number>(0);
    const [activeShip, setActiveShip] = useState<number>(0);
    const [codeView, setCodeView] = useState<boolean>(false);
    const [jsonStateTree, setJsonStateTree] = useState<string>(projectBuilderToString(currentProject));
    const [jsonValid, setJsonValid] = useState<number>(0);  //keep adding padding if JSON is updated incorrectly
    const [activeTab, setActiveTab] = useState<string>("Components");

    const projectOptions = existingProjectNames.map((name) => {
        return (<option key={name}>{name}</option>)
    })

    const projectListItems = existingProjectNames.map((name, i) => {
        let is_highlight_project = (
            //if assembly is showing, highlight the projects in the assembly. Otherwise just highlight the active one
            (
                isShowAssembly && currentAssembly.projects.includes(name)
            )
        ) ? true : false
        let is_current_project = (currentProject.name === name)
        return (<li key={`p_${i}`}>
                    <div className="field has-addons">
                        <a className={`control is-clipped is-expanded ${((is_highlight_project && !is_current_project) ? "has-background-grey has-text-light" : "")} ${(is_current_project) ? "has-background-primary has-text-light" : ""}`}
                            onClick={() => dispatch(changeProject(name))}
                            style={{textOverflow: "ellipsis"}}
                            title={name}
                        >
                    
                        {name}
                    
                        </a>
                        <div className={`control`}>
                            <button className="button is-inverted has-text-success"
                                aria-label="rename project"
                                onClick={() => {
                                    let input = window.prompt("New Project Name:");
                                    if (input){
                                        dispatch(renameProject({old_name: name, new_name: input}));
                                    }
                                }}
                                title={"Rename Project"}
                            >
                                <span className="icon">
                                    <FontAwesomeIcon icon={faPen}/>
                                </span>
                            </button>
                        </div>
                        {
                            isShowAssembly ? <div className="control">
                                <button className="button is-inverted has-text-success"
                                    aria-label={`toggle project in assembly ${is_highlight_project ? "(remove)": "(add)"}`}
                                    onClick={() => {
                                        
                                        if (currentAssembly.projects.includes(name)){
                                            dispatch(removeProjectFromAssembly(name));
                                        } else {
                                            dispatch(addProjectToAssembly(name));

                                            //add all the tags from the project to the assembly, if they are not already there
                                            let currentTags = currentAssembly.tags.filter(t => t.active)
                                            let currentTagNames = currentTags.map(t => t.name)
                                            for (let p of availableProjects) {
                                                if (p.name === name) {
                                                    //this is the relevant project
                                                    //if (currentAssembly.include_untagged_comps) {
                                                        ///add comp tags
                                                        for (let comp_key of Object.keys(p.components)) {
                                                            let comp = p.components[comp_key]
                                                            if (comp && comp.tags) {
                                                                for (let tag of comp.tags) {
                                                                    if (!currentTagNames.includes(tag)){
                                                                        dispatch(addTagToCurrentAssembly(tag))
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    //}
                                                    //if (currentAssembly.include_untagged_ships) {
                                                        //add relationship tags
                                                        for (let ship_key of Object.keys(p.ships)) {
                                                            let ship = p.ships[ship_key]
                                                            if(ship && ship.tags){
                                                                for (let tag of ship.tags) {
                                                                    if (!currentTagNames.includes(tag)){
                                                                        dispatch(addTagToCurrentAssembly(tag))
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    //}
                                                }
                                            }
                                        }
                                    }}
                                    title={`Toggle Project in Assembly ${is_highlight_project ? "(remove)": "(add)"}`}
                                >
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faPlusMinus}/>
                                    </span>
                                </button>
                            </div> : <></>
                        }
                        
                        
                    
                    </div>
                    
                </li>)
    })

    const assemblyOptions = existingAssemblyNames.map((name) => {
        return (<option key={name}>{name}</option>)
    })

    const options = useAppSelector(state => state.ui.isShowAssembly ? currentAssembly.options : currentProject.options)

    function convertJsonToStateTree(s: string, file_name: string | null) {
        let updatedJson = s
        setJsonStateTree(updatedJson)  //set before converting to allow display of malformed json
        //console.log("Direct edit")
        //console.log(updatedJson)
        try {
            let restructured: ProjectBuilder = restructureState(updatedJson);
            console.log(JSON.stringify(restructured))
            //setJsonStateTree(projectBuilderToString(restructured))

            dispatch(updateProject(restructured))

            setJsonValid(0)
        } catch(e) {
            console.log("Error converting json to state, markup the editor", e)
            setJsonValid(((jsonValid<6)?jsonValid + 1:6))
            //console.log(jsonValid)
        }
    }



    const addProject = (e: React.MouseEvent<HTMLButtonElement>) => {
        //e.currentTarget.classList.remove("pulse");
        let input = window.prompt("New Project Name:");
        if (input){
            //let new_id = ( ( existingProjectIds.length > 0 ) ? Math.max( ...existingProjectIds, 0 ) + 1 : 1 );
            dispatch(newProject({
                ...newProjectBuilder,
                name: input,
            }));
            dispatch(changeProject(input));
            //let select = document.getElementById("projectSelect") as HTMLSelectElement | null;
            //if ( select ) {
                //console.log("Updating project selection on new")
                //select.value = input
            //} else {
                //console.log("Could not update project selection on new")
            //}

        }

    }


    const cloneProject = (e: React.MouseEvent<HTMLButtonElement>) => {
        let input = window.prompt("New Project Name:");
        if (input){
            //let new_id = ( ( existingProjectIds.length > 0 ) ? Math.max( ...existingProjectIds, 0 ) + 1 : 1 );
            dispatch(newProject({
                ...currentProject,
                name: input,
            }));
            dispatch(changeProject(input));

        }

    }

    const addAssembly = (e: React.MouseEvent<HTMLButtonElement>) => {
        //e.currentTarget.classList.remove("pulse");
        let input = window.prompt("New Assembly Name:");
        if (input){
            //let new_id = ( ( existingProjectIds.length > 0 ) ? Math.max( ...existingProjectIds, 0 ) + 1 : 1 );
            dispatch(newAssembly({
                ...newAssemblyObject,
                name: input,
            }));
            dispatch(changeAssembly(input));
        }

    }

    const relabelProject = (e: React.MouseEvent<HTMLButtonElement>) => {
        //e.currentTarget.classList.remove("pulse");
        localconsole("moved")
    }

    const relabelAssembly = (e: React.MouseEvent<HTMLButtonElement>) => {
        //e.currentTarget.classList.remove("pulse");
        let input = window.prompt("New Assembly Name:");
        if (input){
            dispatch(renameAssembly({old_name: currentAssembly.name, new_name: input}));
        }
    }

    
    //update the json automatically when the project changes
    useEffect(() => {
        localconsole("current project changed, updating JSON")
        let diagramStateTree_string = projectBuilderToString(currentProject)
        //dispatch(updateProject(currentProject))
        setJsonStateTree(diagramStateTree_string)
        setJsonValid(0)
    }, [dispatch, currentProject]);

    useEffect(() => {
        //if we just removed the last project, go to defaults
        if ( existingProjectNames.length === 0 ) {
            localconsole("Setting to default project")
            //reset 
            //dispatch(updateProject(newProjectBuilder))
            let defaultProject = {
                ...newProjectBuilder,
                name: "new",
                components: [
                    /* {
                        ...newComp,
                        name: "Add Components"
                    },
                    {
                        ...newComp,
                        name: "Add Columns"
                    },
                    {
                        ...newComp,
                        name: "Add Relationships"
                    } */
                ],
                ships: [
                    /* {
                        ...newShip,
                        from: "Add Components",
                        to: "Add Columns",
                        cardinality: "DoT"
                    },
                    {
                        ...newShip,
                        from: "Add Columns",
                        to: "Add Relationships",
                        cardinality: "ArO"
                    },
                    {
                        ...newShip,
                        from: "Add Components",
                        to: "Add Relationships",
                        cardinality: "ArO"
                    } */
                ],


            };
            localconsole(defaultProject)
            dispatch(newProject(restructureState(JSON.stringify(defaultProject))))
            dispatch(changeProject("new"))
            dispatch(newSVG(""))
        }

        //do the same for assembly
        if ( existingAssemblyNames.length === 0 ) {
            localconsole("Setting to default assembly")
            let defaultAssembly = {
                ...newAssemblyObject,
                name: "new",
            }
            dispatch(newAssembly(defaultAssembly))
            dispatch(changeAssembly("new"))
            dispatch(newSVG(""))
        }
    }, [existingProjectNames, existingAssemblyNames, dispatch]);

  return (
      <div style={{height: "100%"}}>
        <section className="hero is-primary">
                    <div className="hero-body">
                        <p className="title">
                            Builder
                        </p>
                    </div>
        </section>
          <div className="columns">
              {/*Left side*/}

                

                    {/*TODO - extract into sidebar component, maybe. Need to consider mobile behaviour too*/}
                  <div className={"column is-2 has-text-centered-mobile box m-3" + ((formsVisible)?"":" is-hidden")}>

                    {/* TODO - Search */}
                    {/* <aside className="menu p-2">
                        <p className="menu-label has-text-primary">
                        Search
                        </p>
                        <div>Search bar probably does not need title</div>
                    </aside> */}

                        {/* Diagram Menu / Assembly Selector */}
                        {isShowAssembly ? <>
                            <aside className="menu p-2">
                          <p className="menu-label has-text-primary">
                            Assembly
                          </p>
                          
                          
                            
                          <div className="field has-addons">
                              <div className="control is-expanded">
                                  <div className="select is-fullwidth">
                                      <select id={"assemblySelect"} onChange={(e) => {
                                          dispatch(changeAssembly(e.currentTarget.value))
                                      }
                                      }
                                              value={currentAssembly.name}
                                      >
                                          {assemblyOptions}
                                      </select>
                                  </div>
                              </div>
                              <div className="control">
                                  <button className="button is-inverted has-text-success"
                                        aria-label="rename assembly"
                                        onClick={relabelAssembly}
                                        title={"Rename Assembly"}
                                    >
                                      <span className="icon">
                                          <FontAwesomeIcon icon={faPen}/>
                                      </span>
                                  </button>
                              </div>
                              {/* <div className="control">
                                  <button className="button is-inverted has-text-success"
                                        aria-label="new assembly"
                                        onClick={addAssembly}
                                        title={"New Assembly"}
                                    >
                                      <span className="icon">
                                          <FontAwesomeIcon icon={faPlus}/>
                                      </span>
                                  </button>
                              </div> */}
                              
                          </div>
                          
                      </aside>
                      <div className={"buttons  is-centered"}>

                          <button className={`button`}
                                disabled={(existingAssemblyNames.length === 1 && currentAssembly.name === "new") ? true : false}
                                aria-label="delete assembly"
                                onClick={() => {
                                    //console.log(`Deleting assembly ${currentAssembly}`)
                                    setJsonValid(0);
                                    dispatch(removeAssembly(currentAssembly.name));
                                    
                                    //don't get into a weird state, load the next diagram
                                    if ( existingAssemblyNames.length > 0 ) {
                                        localconsole("Switching to next assembly")
                                        for (let p = availableAssemblies.length; p > 0; p--) {
                                            
                                            if (availableAssemblies[p-1].name !== currentAssembly.name) {
                                                dispatch(changeAssembly(availableAssemblies[p-1].name))
                                            }
                                        }
                                    }
                                    
                                }
                        }
                        title={"Delete Assembly"}
                        >
                            <span>Delete</span>
                            <span className={"icon has-text-danger"}>
                                <FontAwesomeIcon icon={faTrash} />
                            </span>
                        </button>

                        <button className="button"
                                        aria-label="new assembly"
                                        onClick={addAssembly}
                                        title={"New Assembly"}
                                    >
                                        <span>New</span>
                                      <span className="icon has-text-success">
                                          <FontAwesomeIcon icon={faPlus}/>
                                      </span>
                                  </button>

                      </div>
                        </> : <></>}
                      


                      {/* Diagram Menu / Project Selector */}
                      <aside className="menu p-2">
                          <p className="menu-label has-text-primary">
                              Projects
                          </p>
                          <ul className="menu-list">
                                
                                {projectListItems}
                            </ul>
                          
                            
                          {/* <div className="field has-addons">
                              <div className="control is-expanded">
                                  <div className="select is-fullwidth">
                                      <select id={"projectSelect"} onChange={(e) => {
                                          //let project_slice = availableProjects.filter(p => p.name === e.currentTarget.value)
                                          //console.log("Switching to slice:")
                                          //console.log(project_slice[0].diagram)
                                          //dispatch(updateProject(project_slice[0]))
                                          dispatch(changeProject(e.currentTarget.value))
                                      }
                                      }
                                              value={currentProject.name}
                                      >
                                          {projectOptions}
                                      </select>
                                  </div>
                              </div>
                              <div className="control">
                                  <button className="button is-inverted has-text-success"
                                        aria-label="rename project"
                                        onClick={relabelProject}
                                        title={"Rename Project"}
                                    >
                                      <span className="icon">
                                          <FontAwesomeIcon icon={faPen}/>
                                      </span>
                                  </button>
                              </div>
                              <div className="control">
                                  <button className="button is-inverted has-text-success"
                                        aria-label="new project"
                                        onClick={addProject}
                                        title={"New Project"}
                                    >
                                      <span className="icon">
                                          <FontAwesomeIcon icon={faPlus}/>
                                      </span>
                                  </button>
                              </div>
                              
                          </div> */}
                          
                      </aside>
                      <div className={"buttons  is-centered"}>

                          <button className={`button`}
                                disabled={(existingProjectNames.length === 1 && currentProject.name === "new") ? true : false}
                                aria-label="delete project"
                                onClick={() => {
                                    //console.log(`Deleting project ${currentProject}`)
                                    setJsonValid(0);
                                    dispatch(removeProject(currentProject.name));
                                    //localconsole(existingProjectNames)
                                    //localconsole(availableProjects)
                                    //don't get into a weird state, load the next diagram
                                    if ( existingProjectNames.length > 0 ) {
                                        localconsole("Switching to next project")
                                        for (let p = availableProjects.length; p > 0; p--) {
                                            //localconsole(`Checking ${availableProjects[p-1].name} against ${currentProject.name}`)
                                            if (availableProjects[p-1].name !== currentProject.name) {
                                                //dispatch( updateProject(availableProjects[p-1]) )
                                                dispatch(changeProject(availableProjects[p-1].name))
                                            }
                                        }
                                    }
                                    
                                }
                        }
                        title={"Delete Project"}
                        >
                            <span>Delete</span>
                            <span className={"icon has-text-danger"}>
                                <FontAwesomeIcon icon={faTrash} />
                            </span>
                        </button>

                        <button className="button"
                                        aria-label="new project"
                                        onClick={addProject}
                                        title={"New Project"}
                                    >
                                        <span>New</span>
                                      <span className="icon has-text-success">
                                          <FontAwesomeIcon icon={faPlus}/>
                                      </span>
                                  </button>

                                  <button className="button"
                                        aria-label="clone project"
                                        onClick={cloneProject}
                                        title={"Clone Project"}
                                    >
                                        <span>Clone</span>
                                      <span className="icon has-text-link">
                                          <FontAwesomeIcon icon={faCopy}/>
                                      </span>
                                  </button>

                      </div>

                      
                          
                         
                            {/* <p>
                                Add a new Tab 'Notes' and link these to relationships and comps. Allow description on comps and ships to be 'shown as a note'
<code>
                                @startuml
class Dummy
Dummy --&gt; Foo : A link
note on link: note on the link

Dummy --&gt; Foo2 : Another link
note as n1
this is my note
end note
n1 .. Foo2
@enduml</code>

                            </p> */}
                            

                  </div>



              {/*Right side*/}

                  <div className="column">
                      <div className={"hero is-halfheight "}>

                    <div className={"hero-body container"}>

            <div className={"columns"}>
                <div className={"column" + ((formsVisible)?"":" is-hidden")}>

                    <div className={""}>
                        <div className={"block"}>
                            <div className="box">
                                <div className={"level"}>
                                    <div className={"level-left"}>
                                        <div className={"level-item"}>
                                            <div className={"subtitle"}>
                                                <div className="tabs">
                                                    <ul>
                                                        {!diagramOptions.Gantt ? <li className={activeTab === "Components" ? "is-active" : ""}>
                                                                            <a data-tab={"Components"}
                                                                                onClick={(e) => {
                                                                                   setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                                                   setCodeView(false);
                                                                                }
                                                                                }>Components</a>
                                                                        </li>:<></>}
                                                        {diagramOptions.Gantt ? <li className={activeTab === "Events" ? "is-active" : ""}>
                                                                            <a data-tab={"Events"}
                                                                               onClick={(e) => {
                                                                                   setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                                                   setCodeView(false);
                                                                               }
                                                                               }>Events</a>
                                                                        </li>:<></>}
                                                            <li className={activeTab === "Relationships" ? "is-active" : ""}>
                                                                            <a data-tab={"Relationships"}
                                                                               onClick={(e) => {
                                                                                   setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                                                   setCodeView(false);
                                                                               }
                                                                               }>Relationships</a>
                                                                        </li>
                                                    <li className={activeTab === "JSON" ? "is-active" : ""}>
                                                        <a className="" data-tab={"JSON"}
                                                           onClick={(e) => {
                                                               setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                               setCodeView(true)
                                                           }
                                                           }>

                                                                <span className={"icon"}>

                                                                    <FontAwesomeIcon icon={faDiagramProject}/>

                                                                </span>
                                                            <span>JSON</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className={"level-right"}>

                                    {(!codeView) ?
                                        <div className={"level-item"}>
                                            <button className={"button is-fullwidth"}
                                                    onClick={() => {
                                                        switch(activeTab) {
                                                            case "Components":
                                                                dispatch(sortComps())
                                                                break
                                                            case "Relationships":
                                                                dispatch(sortShips())
                                                                break
                                                            case "Events":
                                                                break
                                                            default:
                                                                break
                                                        }
                                                    }}>
                                            <span className={"icon"}>
                                                <FontAwesomeIcon icon={faArrowDownAZ}/>
                                            </span>

                                            </button>
                                        </div>
                                            : <></>
                                        }

                                    </div>
                                </div>


                                {(codeView) ?
                                    <div
                                        className={((jsonValid === 0) ? "" : (" has-background-danger p-" + jsonValid))}>
                                        <div className={"card is-shadowless"}>
                                            <JSONBuilder key={"jsonbuilding"} jsonStateTree={jsonStateTree}
                                                         restructureState={(e) => convertJsonToStateTree(e.currentTarget.value, null)}/>
                                        </div>
                                    </div> :
                                    (()=>{
                                        switch(activeTab) {
                                            case "Components":
                                                return !diagramOptions.Gantt ? <div><Comps setActiveComp={setActiveComp} active_component_id={activeComp} /></div> : <></>
                                            case "Relationships":
                                                return <div
                                                    className={"has-text-centered-mobile"}>
                                                    <Ships setActiveShip={setActiveShip} active_ship_id={activeShip} />
                                                </div>
                                            /* case "Events":
                                                return diagramOptions.Gantt ? <div>
                                                    <Events setActiveComp={setActiveComp} active_component_id={activeComp} />
                                                </div> : <></> */
                                            default:
                                                return <></>
                                        }
                                    })()
                                }
                            </div>
                        </div>
                        {/*<div className={"field has-text-centered"}>
                            <div className="control">
                                <button className={"button is-danger"} onClick={() => {setJsonValid(0); dispatch(resetSlice())}}>
                                    <span className={"icon"}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </span>
                                </button>
                            </div>
                        </div>*/}
                    </div>
                </div>


                <div className={"column container"}>

                    <Diagram key={"berd"} isExpanded={!formsVisible} setFormsVisible={setFormsVisible}/>
                            { isShowAssembly ? <Options 
                                title={"Tags"} 
                                options={currentAssembly.tags.reduce((acc, t) => {acc[t.name]= t.active; return acc}, {} as ItemProps)} 
                                toggleCallback={(o: string) => dispatch(toggleTagInCurrentAssemly(o))}
                                removeCallback={(o: string) => dispatch(removeTagFromAssembly(o))}
                                newCallback={() => {
                                    let input = window.prompt("Add Tag:");
                                    if (input){
                                        dispatch(addTagToCurrentAssembly(input))
                                    }
                                }}
                            /> : <></> }
                    <Options
                    title={"Options"}
                    options={ isShowAssembly ? {
                        ...options,
                        "Tagless Components": currentAssembly.include_untagged_comps,
                        "Tagless Relationships": currentAssembly.include_untagged_ships,
                    } : options }
                    colors={ isShowAssembly ? currentAssembly.colors : currentProject.colors }
                    toggleCallback={(o: string) => {
                        if (["Tagless Components", "Tagless Relationships"].includes(o)) {
                            dispatch(toggleUnTagged(o))
                        } else {
                            isShowAssembly ? dispatch(toggleAssemblyOption(o)) : dispatch(toggleOption(o))
                        }
                    }}
                    colorCallback={(kv) => isShowAssembly ? dispatch(setAssemblyColor(kv)) : dispatch(setColor(kv))}
                    />
                </div>

            </div>
                    </div>
                      </div>
                  </div>


          </div>

      </div>

  );
}

export default Builder;