import React from "react";
import {useAppDispatch, useAppSelector} from "../state/hooks";
//TODO 20241210 - get these from elsewhere and target a specific project/assembly. import {toggleOption, setColor} from "../state/slices/diagramSlice";
import ColorPicker from "./ColorPicker";
//import { createSelector } from "@reduxjs/toolkit";
//import { newProjectBuilder, ProjectBuilder } from "../state/objects/projectBuilder";
import { selectCurrentProject, selectCurrentProjectName, selectProjects, setColor, toggleOption, updateProject } from "../state/slices/systemSlice";
import { ColorDictionary, KVProps } from "../state/objects/types";
import { localconsole } from "../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusMinus } from "@fortawesome/free-solid-svg-icons";

export interface ItemProps {
  [key: string]: boolean
}


export interface OptionsProps {
    title: string,
    options: ItemProps,
    colors?: ColorDictionary,
    toggleCallback: (o: string) => void,
    colorCallback?: (kv: KVProps) => void,
    removeCallback?: (o: string) => void,
    newCallback?: () => void,
}

function Options(props: OptionsProps) {
    
    const currentProject = useAppSelector(state => selectCurrentProject(state));
    
    //const colors = useAppSelector(state => currentProject.colors)

    const dispatch = useAppDispatch()

    const itemList = (options: ItemProps) => Object.keys(options).map((i, n) => {

        return (
            <span className={"tag p-0"} style={{background: "#E6E6E6"}} key={i}>
                <div className="tags has-addons" onClick={() => props.toggleCallback(i)}>
                    <span className={"tag " + ((options[i])?"is-success":"is-danger")}></span>
                    <span className="tag is-dark">{i}</span>
                    {
                        props.removeCallback ? (
                            <a className="tag is-delete" onClick={(e) => {
                                if (props.removeCallback) {
                                    props.removeCallback(i)
                                }
                            }}></a>
                        ) : <></>
                    }
                </div>
            </span>
        )
    })

  return (
      <div className={`has-text-centered-mobile`}>
          <div className={"subtitle"}>{props.title}</div>
          <div className={"columns level"}>
                
              <div className={"column level-item"}>
                  <div className={"tags is-centered"}>
                      {itemList(props.options)}
                  </div>
              </div>

              {props.newCallback ? <>
                    <div className="column level-item has-text-centered is-narrow">
                        <button className="button pulse is-success is-inverted is-rounded p-4"
                            aria-label="add tag"
                            
                            onClick={() => { if (props.newCallback) {props.newCallback()}}}
                            onMouseOver={(e) => {
                                e.currentTarget.classList.remove("pulse");
                            }}
                            title={"Add Tag"}
                        >
                            
                            <span className="icon has-text-success">
                                <FontAwesomeIcon icon={faPlus}/>
                            </span>
                        </button>
                    </div>
                </> : <></>}
              
                {props.colors ? <div className={"column level-item"}>
                  <div className={"field"}>

                      <ColorPicker color={props.colors["Background"]} setColor={ (kv) => props.colorCallback ? props.colorCallback(kv) : localconsole("This is a bug, missing colorCallback function") }
                                   label={"Background"} name={"Background"} centered={true}
                      />

                      {/*<ColorPicker color={colors["Table"]} setColor={(kv) => dispatch(setColor(kv))} label={"Table"} name={"Table"} centered={true} />
*/}
                  </div>
              </div>
: <></>}
          </div>

          

      </div>

  );
}

export default Options;