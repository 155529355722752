import React from "react";
import { useAppSelector, useAppDispatch } from '../state/hooks'
//TODO 20241210 - get these from elsewhere.
/*import {
    addShip,
    ShipFieldsDictionary,
     delShip,
    copyShip,
    Ship, CompDictionary, getCompReference,
} from "../state/slices/diagramSlice"; */
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faPlus, faTrash, faCopy, faArrowRightArrowLeft, faTag} from "@fortawesome/free-solid-svg-icons";
import ColorPicker from "./ColorPicker";
import ShipsInput from "./ShipsInput";
import OptionalSelect from "./optionalSelect";
//import { createSelector } from "@reduxjs/toolkit";
import { copyShip, selectCurrentProject, delShip, selectCurrentProjectName, selectProjects, updateProject, upsertShip, tagShip, removeShipTag, selectCurrentProjectShips, selectCurrentProjectShipIds, selectCurrentProjectComponents } from "../state/slices/systemSlice";
import { newProjectBuilder, ProjectBuilder } from "../state/objects/projectBuilder";
import { getCompReference } from "../state/objects/comp";
import { Ship, ShipFieldsDictionary } from "../state/objects/ship";

//TODO change the component to selection from existing
//TODO change the relationship type to dropdown on table record

interface ShipsProps {
    setActiveShip: any,
    active_ship_id: number,
}
/*
interface FormProps {
    shipFrom: string, setShipFrom: Dispatch<SetStateAction<string>>,
    shipFromCol: string, setShipFromCol: Dispatch<SetStateAction<string>>,
    shipCardinality: string, setShipCardinality: Dispatch<SetStateAction<string>>,
    shipTo: string, setShipTo: Dispatch<SetStateAction<string>>,
    shipToCol: string, setShipToCol: Dispatch<SetStateAction<string>>,
    shipFreestyle: string, setShipFreestyle: Dispatch<SetStateAction<string>>,
}*/
/*

interface FieldProps {
    label: string,
    placeholder: string,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,

}
*/

/*

const ItemFormField = (props: FieldProps) => (
    <div className="field" key={props.label}>
        <label className="label">{props.label}</label>
        <div className="control">
            <input className="input"
                   type={"text"}
                   placeholder={props.placeholder}
                   onChange={props.onChange}
                   value={props.value}
            ></input>
        </div>
    </div>
)

const ItemFormFields = (props: FormProps) => (
        <div className={"field"}>
            <ItemFormField label={"From"} placeholder={"Component Name"}
                            onChange={(e) => props.setShipFrom(e.currentTarget.value)}
                            value={props.shipFrom}

            />
            <ItemFormField label={"To"} placeholder={"Component Name"}
                            onChange={(e) => props.setShipTo(e.currentTarget.value)}
                            value={props.shipTo}

            />


            <div className="field" key={"Cardinality"}>
                <label className="label">{"Cardinality"}</label>
                <div className="control">
                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"1:1"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        1:1
                    </label>
                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"1:M"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        1:M
                    </label>
                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"M:1"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        M:1
                    </label>
                </div>
                <div className="control">

                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"ExO"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        Exactly One
                    </label>
                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"ArO"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        Arrow
                    </label>

                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"OoM"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        <span>One or Many</span>
                    </label>
                    <label className="radio">
                        <input type="radio" name="Cardinality"
                               value={"DoT"}
                               onChange={(e) => props.setShipCardinality(e.currentTarget.value)}
                        ></input>
                        Dotted
                    </label>
                </div>
            </div>



            <ItemFormField label={"Source Column"} placeholder={"Column Name"}
                            onChange={(e) => props.setShipFromCol(e.currentTarget.value)}
                            value={props.shipFromCol}

            />
            <ItemFormField label={"Target Column"} placeholder={"Column Name"}
                            onChange={(e) => props.setShipToCol(e.currentTarget.value)}
                            value={props.shipToCol}

            />
            <ItemFormField label={"Other"} placeholder={"Complex Relationship"}
                            onChange={(e) => props.setShipFreestyle(e.currentTarget.value)}
                            value={props.shipFreestyle}

            />
        </div>
    )
*/


function Ships(props: ShipsProps) {
    
    const currentProject = useAppSelector(state => selectCurrentProject(state));
    
    const items = useAppSelector(state => selectCurrentProjectShips(state))
    const existingShipIds = useAppSelector(state => selectCurrentProjectShipIds(state))
    //const existingComponentIds = useAppSelector(state => state.diagram.existingComponentIds)
    const components = useAppSelector(state => selectCurrentProjectComponents(state))
    const comp_names: string[] = Object.entries(components).map(([s, c], i) => getCompReference(c) ?? "").filter((s) => {return s !== ""})

    const dispatch = useAppDispatch()

    /*const [showNewRowForm, setShowNewRowForm] = useState<boolean>(false);
    const [shipFrom, setShipFrom] = useState<string>("");
    const [shipFromCol, setShipFromCol] = useState<string>("");
    const [shipCardinality, setShipCardinality] = useState<string>("1:1");
    const [shipTo, setShipTo] = useState<string>("");
    const [shipToCol, setShipToCol] = useState<string>("");
    const [shipFreestyle, setShipFreestyle] = useState<string>("");*/

    const newItem = () => {
        let new_id = ( ( existingShipIds.length > 0) ? Math.max( ...existingShipIds, 0 ) + 1 : 1 );
        dispatch(upsertShip({
            id: new_id,
            from: "a",
            to: "b",
            cardinality: "ArO",
            tags: [],
        }))
    }

    /*const cancelForm = () => {
        setShowNewRowForm(false);
        setShipFrom("");
        setShipFromCol("");
        //setShipCardinality("1:1");
        setShipTo("");
        setShipToCol("");
        setShipFreestyle("");
    }*/

    /*const saveForm = () => {
        let s: Ship = {
            "id": (existingShipIds.length > 0)?existingShipIds[existingShipIds.length - 1] + 1: 1,
            "from": shipFrom,
            "to": shipTo,
            "cardinality": shipCardinality,
        };
        if ( shipFromCol != "" ) { s["from_col"] = shipFromCol }
        if ( shipToCol != "" ) { s["to_col"] = shipToCol }
        if ( shipFreestyle != "" ) { s["freestyle"] = shipFreestyle }
        dispatch(addShip(s))
        cancelForm()
        setShowNewRowForm(true);
    }*/

    const binShip = (item_id: number) => {
        dispatch(delShip(item_id))
    }

    const cloneShip = (item_id: number) => {
        dispatch(copyShip(item_id))
    }

    const activeShip = items[props.active_ship_id]
    const activeShipTags = activeShip?.tags?.map((tag, i) => {
        return (<div className="control" key={`t_${i}`}>
                    <div className="tags has-addons">
                        <a className="tag">{`${tag}`}</a>
                        <a className="tag is-delete" onClick={(e) => {
                            dispatch(removeShipTag({ship_id: activeShip.id, tag: tag}))
                        }}></a>
                    </div>
                </div>)
    })

    /*const itemList = Object.keys(items).map((i) =>
        <tr key={items[i].id} data-key={items[i].id}>
            <td>{items[i].from}</td>
            <td>{items[i].from_col}</td>
            <td>{items[i].cardinality}</td>
            <td>{items[i].to}</td>
            <td>{items[i].to_col}</td>
            <td>{items[i].freestyle}</td>
            <td>
                <button className={"button is-danger is-inverted is-pulled-right"} onClick={binShip}>
                    <span className={"icon"}>
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </button>
            </td>
        </tr>
    )*/

    const addTag = (ship_id: number) => {
        //e.currentTarget.classList.remove("pulse");
        let input = window.prompt("Add Tag:");
        if (input){
            dispatch(tagShip({ship_id: ship_id, tag: input}))
        }
    }

    const itemAccordian = Object.entries(items).map(([position, ship]) => {
        let iAmActive = props.active_ship_id === ship.id;
        let from_columns = Object.values(components).find((c, i) => getCompReference(c) === ship.from)?.columns;
        let to_columns = Object.values(components).find((c, i) => getCompReference(c) === ship.to)?.columns;


        const activateRow = (id: number) => {
            props.setActiveShip(id);
        }
        const addGrey = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!iAmActive) {
                e.currentTarget.classList.add("has-background-grey-lighter")
            }
        }
        const removeGrey = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.currentTarget.classList.remove("has-background-grey-lighter")
        }
        const toggleRow = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            removeGrey(e)
            activateRow(iAmActive?0:ship.id)
        }
        function cardinalityDisplay(cardinality: string) {
            let ret;
            switch (cardinality) {
                case "1:1":
                    ret = "―"
                    break;
                case "1:M":
                    ret = "ᗕ"
                    break;
                case "M:1":
                    ret = "ᗒ"
                    break;
                case "ExO":
                    ret = "Exactly One"
                    break;
                case "OoM":
                    ret = "One or Many"
                    break;
                case "ZoO":
                    ret = "Zero or One"
                    break;
                case "ZoM":
                    ret = "Zero or Many"
                    break;
                case "ArO":
                    ret = "→"
                    break;
                case "DoT":
                    ret = "⋯"
                    break;
                case "---":
                    ret = "┄"
                    break;
                case "Cmp":
                    ret = "Composition"
                    break;
                case "Agg":
                    ret = "Aggregation"
                    break;
                case "Ext":
                    ret = "Extension"
                    break;
                case "Imp":
                    ret = "Implements"
                    break;
                default:
                    ret = "1:1"
                    break;
            }
            return ret;

        }

        return (
            <div className={"card is-shadowless"}
                 key={`${ship.id}_${ship.from}`} data-key={ship.id}
            >
                <header className={"card-header" +
                    ((iAmActive) ? " has-background-primary has-text-light" : "")
                    }
                    onClick={toggleRow}
                    onMouseEnter={addGrey}
                    onMouseLeave={removeGrey}
                >

                    <p className={"card-header-title" +
                        " has-text-weight-light" +
                            ((iAmActive) ? " has-text-light " : "")
                        }
                    >
                        <span>{ship.from}</span>
                        <span className={"px-1"}>{cardinalityDisplay(ship.cardinality)}</span>
                        <span>{ship.to}</span>
                    </p>

                    {(iAmActive) ? <>
                            <button className={"card-header-icon "}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        binShip(ship.id)
                                    }}
                                    title={"Delete Relationship"}
                            >
                                <span className={"icon"}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </span>
                            </button>
                            <button className={"card-header-icon  "} onClick={(e) => {
                                e.stopPropagation();
                                cloneShip(ship.id)
                            }}
                                    title={"Copy Relationship"}
                            >
                            <span className={"icon"}>
                                <FontAwesomeIcon icon={faCopy}/>
                            </span>
                            </button>

                            <button className={"card-header-icon"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(upsertShip({
                                            ...ship,
                                            from: ship.to,
                                            to: ship.from,
                                        } as Ship))
                                    }}
                                    title={"Reverse Relationship"}
                            >
                                <span className={"icon"}>
                                    <FontAwesomeIcon icon={faArrowRightArrowLeft}/>
                                </span>
                            </button>
                            <button className={"card-header-icon"} aria-label="add tag"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addTag(ship.id)
                                }}
                                title={"Tag Relationship"}
                            >
                                <span className={"icon"}>
                                    <FontAwesomeIcon icon={faTag}/>
                                </span>
                            </button>
                        </>
                        : null}
                    <button className="card-header-icon" aria-label="more options">
                      <span className="icon">
                        {(iAmActive) ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}
                      </span>
                    </button>
                </header>
                {(iAmActive) ?
                    <div className={"card-content"}>
                        <div className={"block"}>

                        {(ship.tags?.length > 0) ? <div className="field is-horizontal">
                                        <div className="field-label">
                                            <label className="label">Tags</label>
                                        </div>
                                        <div className="field-body">
                                        <div className="field is-grouped is-grouped-multiline">
                                            {activeShipTags}                                        
                                    </div>
                                </div>
                            </div>:<div></div>}

                            <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">From</label>
                            </div>
                            <div className="field-body">
                                <div className="field has-addons">
                                    <div className="control is-expanded">
                                        {/*<ShipsInput record={ship} field={ShipFieldsDictionary.from}
                                                    setRecord={(c) => dispatch(addShip(c))}
                                                    activateRecord={activateRow}/>*/}
                                        <OptionalSelect options={comp_names} placeholder={"Select an option"} value={ship.from}
                                                        onSelection={(selection: string) => {
                                            let newRecord: Ship = {
                                                ...ship,
                                                from: selection
                                            }
                                            dispatch(upsertShip(newRecord))
                                        }}/>
                                    </div>
                                    {/*<div className={"control"}>
                                        <button className={"button is-success"} onClick={() => cloneShip(ship.id)}
                                                title={"Copy Relationship"}
                                        >
                                            <span className={"icon"}>
                                                <FontAwesomeIcon icon={faCopy}/>
                                            </span>
                                        </button>
                                    </div>
                                    <div className={"control"}>
                                        <button className={"button is-danger"} onClick={() => binShip(ship.id)}
                                                title={"Delete Relationship"}
                                        >
                                            <span className={"icon"}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </span>
                                        </button>
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">To</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">

                                        <OptionalSelect options={comp_names} placeholder={"Select an option"} value={ship.to} onSelection={(selection: string) => {
                                            let newRecord: Ship = {
                                                ...ship,
                                                to: selection
                                            }
                                            dispatch(upsertShip(newRecord))
                                        }}/>

                                        {/*<ShipsInput record={ship} field={ShipFieldsDictionary.to} setRecord={(c) => dispatch(addShip(c))} activateRecord={activateRow} />*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Cardinality</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <div className="field" key={"Cardinality"}>


                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select id={"rel_select_" + ship.id}
                                                            onChange={(e) => dispatch(upsertShip({...ship, cardinality: e.currentTarget.value}))}
                                                            defaultValue={ship.cardinality}

                                                    >
                                                        <optgroup label="Relational">
                                                            <option value={"1:1"}>{cardinalityDisplay("1:1")}</option>
                                                            <option value={"1:M"}>{cardinalityDisplay("1:M")}</option>
                                                            <option value={"M:1"}>{cardinalityDisplay("M:1")}</option>
                                                            <option value={"ExO"}>{cardinalityDisplay("ExO")}</option>
                                                            <option value={"OoM"}>{cardinalityDisplay("OoM")}</option>
                                                            <option value={"ZoO"}>{cardinalityDisplay("ZoO")}</option>
                                                            <option value={"ZoM"}>{cardinalityDisplay("ZoM")}</option>
                                                        </optgroup>
                                                        <optgroup label="Annotation">
                                                            <option value={"ArO"}>{cardinalityDisplay("ArO")}</option>
                                                            <option value={"DoT"}>{cardinalityDisplay("DoT")}</option>
                                                            <option value={"---"}>{cardinalityDisplay("---")}</option>
                                                            <option value={"Cmp"}>{cardinalityDisplay("Cmp")}</option>
                                                            <option value={"Agg"}>{cardinalityDisplay("Agg")}</option>
                                                            <option value={"Ext"}>{cardinalityDisplay("Ext")}</option>
                                                            <option value={"Imp"}>{cardinalityDisplay("Imp")}</option>
                                                            {/*<option value={"==="}>===</option>*/}
                                                        </optgroup>
                                                    </select>
                                                </div>


                                                {/*<label className="radio">
                                                    <input type="radio" name="Cardinality"

                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    1:1
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" name="Cardinality"

                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    1:M
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" name="Cardinality"

                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    M:1
                                                </label>
                                            </div>
                                            <div className="control">
                                                <label className="radio">
                                                    <input type="radio" name="Cardinality"

                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    Exactly One
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" name="Cardinality"
                                                           value={""}
                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    Arrow
                                                </label>

                                                <label className="radio">
                                                    <input type="radio" name="Cardinality"
                                                           value={""}
                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    <span>One or Many</span>
                                                </label>
                                                <label className="radio">
                                                    <input type="radio" name="Cardinality"
                                                           value={""}
                                                           onChange={(e) => dispatch(addShip({...ship, cardinality: e.currentTarget.value}))}
                                                    ></input>
                                                    Dotted
                                                </label>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">From Col</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        {/*<ShipsInput record={ship} field={ShipFieldsDictionary.fromCol} setRecord={(c) => dispatch(addShip(c))} activateRecord={activateRow} />*/}
                                        <OptionalSelect options={from_columns?.map((c) => c.name ?? c.db_name ?? undefined) ?? []} placeholder={"Select an option"} value={ship.from_col ?? ""}
                                                        onSelection={(selection: string) => {
                                            let newRecord: Ship = {
                                                ...ship,
                                                from_col: selection
                                            }
                                            dispatch(upsertShip(newRecord))
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">To Col</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        {/*<ShipsInput record={ship} field={ShipFieldsDictionary.toCol} setRecord={(c) => dispatch(addShip(c))} activateRecord={activateRow} />*/}
                                        <OptionalSelect options={to_columns?.map((c) => c.name ?? c.db_name ?? "") ?? []} placeholder={"Select an option"} value={ship.to_col ?? ""}
                                                        onSelection={(selection: string) => {
                                            let newRecord: Ship = {
                                                ...ship,
                                                to_col: selection
                                            }
                                            dispatch(upsertShip(newRecord))
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Freestyle</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control">
                                        <ShipsInput record={ship} field={ShipFieldsDictionary.freestyle} setRecord={(c) => dispatch(upsertShip(c))} activateRecord={activateRow} />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <ColorPicker color={(ship.colors ?? {})["Line"] ?? "#808080"} setColor={(kv) => {
                                            let c: Ship = {
                                                ...ship,
                                                colors: {...ship.colors}  //clone so I can modify
                                            };
                                            (c.colors ?? {})[kv.key] = kv.value
                                            dispatch(upsertShip(c))
                                        }}
                                            name={"Line"}
                                            label={"Line"}
                                            boldLabel={true}
                        />
                    </div>
                </div>:<div></div>}
            </div>
         )
    })


  return (
    <div className={""}>
        <div className={"block"}>
            {itemAccordian}
        </div>
        <div className={"has-text-centered"}>
            <button className={"button is-success is-inverted pulse is-rounded p-4"} onClick={newItem} onMouseOver={(e) => e.currentTarget.classList.remove("pulse")}
                title={"Add Relationship"}
            >
                <span className={"icon"}>
                    <FontAwesomeIcon icon={faPlus} />
                </span>
            </button>
        </div>

            {/*<div className={"block"}>
                <div className={"buttons is-centered"}>
                    <button className={"button is-success" + ((!showNewRowForm)?"":" is-hidden")} onClick={newItem}>
                        <span className={"icon"}>
                            <FontAwesomeIcon icon={faPlus} />
                        </span>
                        <span>Add</span>
                    </button>
                </div>
            </div>
            <div className={"block" + ((showNewRowForm)?"":" is-hidden")}>
                <ItemFormFields
                    shipFrom={shipFrom} setShipFrom={setShipFrom}
                    shipFromCol={shipFromCol} setShipFromCol={setShipFromCol}
                    shipCardinality={shipCardinality} setShipCardinality={setShipCardinality}
                    shipTo={shipTo} setShipTo={setShipTo}
                    shipToCol={shipToCol} setShipToCol={setShipToCol}
                    shipFreestyle={shipFreestyle} setShipFreestyle={setShipFreestyle}
                />
                <div className={"field is-grouped"}>
                    <div className={"control"}>
                        <button className={"button is-success"} onClick={saveForm}>
                            <span className={"icon"}>
                                <FontAwesomeIcon icon={faFloppyDisk} />
                            </span>
                            <span>Save</span>
                        </button>
                    </div>
                    <div className={"control"}>
                        <button className={"button"} onClick={cancelForm}>Cancel</button>
                    </div>
                </div>
            </div>
            {( existingShipIds.length === 0 )?<div></div>:(
            <div className={"box"}>
                <table className={"table is-fullwidth has-text-centered"}>
                    <thead><tr>
                        <th className={"has-text-centered"}>From</th>
                        <th className={"has-text-centered"}>Source</th>
                        <th className={"has-text-centered"}>Cardinality</th>
                        <th className={"has-text-centered"}>To</th>
                        <th className={"has-text-centered"}>Target</th>
                        <th className={"has-text-centered"}>Other</th>
                    </tr></thead>
                    <tbody>{itemList}</tbody>
                </table>
            </div>
            )}*/}
    </div>
  );
}

export default Ships;