import React from "react";

function Home() {

  return (
      <div style={{height: "100%"}}>


          <div className={""}>
              <section className="hero is-primary">
                  <div className="hero-body">
                      <p className="title">
                          What is <span
                          className={"is-family-sans-serif has-text-weight-bold has-text-light"}
                                  >berd
                                  </span>?
                      </p>
                      <p className="subtitle">
                          A tool to draw boxes and lines!
                      </p>
                  </div>
              </section>
              <div className={"hero is-halfheight "}>
                  <div className={"hero-body container"}>


                      <div className="tile is-ancestor">
                          <div className="tile is-vertical">
                              <div className="tile">
                                  <div className="tile is-parent is-vertical">
                                      <article className="tile is-child">

                                          <div className="block">
                                              <ul>
                                                  <li className={"block"}>
                                                      <article className="message">
                                                          <div className="message-header has-background-primary">
                                                              <p>Versatile</p>
                                                              {/*<button className="delete"
                                                                            aria-label="delete"></button>*/}
                                                          </div>
                                                          <div className="message-body">
                                                              Conceptual, logical or physical relationship diagrams can
                                                              be mixed.
                                                          </div>
                                                      </article>
                                                  </li>
                                                  <li className={"block"}>
                                                      <article className="message">
                                                          <div className="message-header has-background-primary">
                                                              <p>Composable</p>
                                                              {/*<button className="delete"
                                                                            aria-label="delete"></button>*/}
                                                          </div>
                                                          <div className="message-body">
                                                                <ul><li>
                                                                <span>Related components can be merged using an <i>Alias</i> or grouped
                                                                by using <i>dot.notation</i> in the identifier.</span>
                                                                </li>
                                                                <li>
                                                                <span>Elements with a <i>Tag</i> can be combined and displayed together in an <i>Assembly</i>, regardless of the <i>Project</i> they belong to.</span>
                                                                </li>
                                                                </ul>
                                                              
                                                              
                                                          </div>
                                                      </article>
                                                  </li>
                                                  <li className={"block"}>
                                                      <article className="message">
                                                          <div className="message-header has-background-primary">
                                                              <p>System Agnostic</p>
                                                              {/*<button className="delete"
                                                                            aria-label="delete"></button>*/}
                                                          </div>
                                                          <div className="message-body">
                                                              Diagrams can be updated manually, or automatically
                                                              rendered using a JSON schema extracted from another system.
                                                          </div>
                                                      </article>
                                                  </li>
                                              </ul>
                                          </div>


                                      </article>
                                      {/*<article className="tile is-child">
                                            <p className="subtitle">What <span className={"is-family-sans-serif has-text-weight-bold has-text-primary"}>berd</span> is not</p>

                                                <div className="block">A pixel perfect design studio</div>
                                                <div className="block">A code generation / validation tool. Diagrams are for people; I would suggest other ways to validate or modify database schemas</div>


                                        </article>*/}
                                  </div>
                                  <div className="tile is-parent">
                                      <article className="tile is-child has-text-centered">
                                          <figure className="image is-inline-block">
                                              <img src="examples/berd_example.svg" alt="Example berd diagram"
                                                   style={{mixBlendMode: "multiply"}}
                                              >
                                              </img>
                                          </figure>
                                      </article>
                                  </div>
                              </div>
                              <div className="tile is-parent">
                                  <article className="tile is-child">
                                      <p>Good documentation is rare, but clarity is critical to effective communication.
                                          I use <span
                                              className={"is-family-sans-serif has-text-weight-bold has-text-primary"}
                                                >berd
                                                </span> to
                                          make a small niche of documentation slightly easier to produce
                                      </p>

                                  </article>
                              </div>
                          </div>

                      </div>


                  </div>
              </div>
          </div>

      </div>

  );
}

export default Home;