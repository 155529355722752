//increment the version so I can 'wipe' it

//TODO 20241210 - get these from elsewhere and reference  specific project/assembly
//import {DiagramSlice, sliceInitial as initialDiagram} from "./slices/diagramSlice";
//import {ProjectSlice, sliceInitial as initialProject} from "./slices/projectSlice";


import {localconsole} from "../App";

const app_version_prefix = 'berd_v'
const app_version = app_version_prefix + 1.2;  //increment this to reset local state when new version is released, so the slate is clean

//this function is used as a handler in the store subscribe() method, it's attached in the app and called when a state change happens
export const onStateChange = (state: any) => {
    try {
        let diagrams = {
            system: state.system,
            assembly: state.assembly,
            //svg: state.svg,
            ui: state.ui,
        };
        let stateString = JSON.stringify(diagrams);
        localStorage.setItem(app_version, stateString);
    } catch(err) {
        //check if localStorage is full
        console.log(err);
        console.log("localStorage is full, remove some diagrams")
    }
};

//loadState from localStorage using current tree version
export const loadState = () => {
    localconsole("ENTER: loadState")
    let ret = undefined;
    try {
        let stateString = localStorage.getItem(app_version);
        if (stateString !== null) {
            let savedState = JSON.parse(stateString);
            //fill in any missing state
            //TODO - commenting out while rejigging the state tree
            /* {
                type diagramKey = keyof DiagramSlice
                let keys: diagramKey[] = Object.keys(initialDiagram) as diagramKey[]
                keys.forEach((k: diagramKey) => {
                    if (savedState.diagram[k] === undefined) {
                        savedState.diagram[k] = initialDiagram[k]
                    }
                    if (k === "options") {
                        if (savedState.diagram[k].Fields === undefined) {
                            savedState.diagram[k].Fields = true
                        }
                        if (savedState.diagram[k].Gantt === undefined) {
                            localconsole("setting Gantt option to false")
                            savedState.diagram[k].Gantt = false
                        }
                    }
                })
            } */
           //TODO - commenting out while rejigging the state tree
           /* 
            {
                type projectKey = keyof ProjectSlice
                let keys: projectKey[] = Object.keys(initialProject) as projectKey[]
                keys.forEach((k: projectKey) => {
                    if (savedState.project[k] === undefined) {
                        savedState.project[k] = initialProject[k]
                    }
                    if (savedState.project.version === undefined) {
                        savedState.project.version = 1
                    }
                })
            } */
            ret = savedState;
        }

        //tidy up the old state
        for (const key in localStorage) {
            if (key == app_version) {
                continue
            } else if (key.startsWith(app_version_prefix)) {
                localStorage.removeItem(key)
            }
        }
    } catch (err) {
        console.log(err);
    }
    return ret;
};