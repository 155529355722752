import { ColorDictionary } from "./types";

//from and to correspond to the index number of the component in the components array
export interface Ship {
    id: number,
    from: string,
    to: string,
    cardinality: string,
    from_col?: string,
    to_col?: string,
    freestyle?: string,
    colors?: ColorDictionary,
    tags: string[],  //arbitrary tags used to group into Assemblies
  }

  export const newShip: Ship = {
    tags: [],
    id: 0,
    from: "",
    to: "",
    cardinality: ""
  }
  
  export enum ShipFieldsDictionary {
      from,
      to,
      cardinality,
      fromCol,
      toCol,
      freestyle,
      colors,
  }
  
  export interface ShipDictionary {
    [key: string]: Ship
  }