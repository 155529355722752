import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../state/hooks";
/* import {
    setDiagramSVG,
    stateToString
} from "../state/slices/diagramSlice"; */
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faCompress, faPuzzlePiece, faArrowsToDot} from "@fortawesome/free-solid-svg-icons";
import {base64_font} from "../fonts/FireSans";
import { selectCurrentProject, selectCurrentProjectName, selectProjects } from "../state/slices/systemSlice";
//import { createSelector, current } from "@reduxjs/toolkit";
import { newProjectBuilder, ProjectBuilder } from "../state/objects/projectBuilder";
import { newSVG, selectSVG } from "../state/slices/svgSlice";
import { localconsole } from "../App";
import { selectAssemblies, selectAssemblyComps, selectAssemblyShips, selectCurrentAssembly, selectCurrentAssemblyName } from "../state/slices/assemblySlice";
import { Assembly, newAssemblyObject } from "../state/objects/assembly";
import { selectShowAssembly, toggleShowAssembly } from "../state/slices/uiSlice";

interface DiagramProps {
    isExpanded: boolean,
    setFormsVisible: Dispatch<SetStateAction<boolean>>
}



function Diagram(props: DiagramProps) {
    
    const currentProject = useAppSelector(state => selectCurrentProject(state));
    const currentAssembly = useAppSelector(state => selectCurrentAssembly(state));
    
    const currentAssemblyComps = useAppSelector(state => selectAssemblyComps(state))
    const currentAssemblyShips = useAppSelector(state => selectAssemblyShips(state))

    const isShowAssembly = useAppSelector(state => selectShowAssembly(state))

    const components = () => isShowAssembly ? currentAssemblyComps : currentProject.components
    const ships = () => isShowAssembly ? currentAssemblyShips : currentProject.ships
    const options = () => isShowAssembly ? currentAssembly.options : currentProject.options  //TODO, allow options for assemblies
    const colors = () => isShowAssembly ? currentAssembly.colors : currentProject.colors
    const dispatch = useAppDispatch()
    
    const diagramSVG = useAppSelector(state => selectSVG(state))
    let inFlightAC = new AbortController()
    let inFlightDelay = setTimeout(() => {return Promise.resolve(false)}, 1)
    //const [inFlightAC, setInFlightAC] = useState<AbortController>(new AbortController());
    //const [inFlightDelay, setInFlightDelay] = useState<NodeJS.Timeout>(setTimeout(() => {return Promise.resolve(false)}, 1))


//changing destination to API gateway to enable caching and potentially throttling in future
//changing back. POST is not cachable on API Gateway so pointless

//https://berd.azure-api.net/berd/http_berd  //API Gateway
//https://berd.azurewebsites.net/api/http_berd  //Function App

    /*const downloadSVG = () => {

        /!*fetch(svg_url)
            .then((res) => {
                return res.text();
            })
            .then((data) => {*!/
                //console.log("data", data)
                let rando_key = Math.random().toString().substring(2,5);
                let elem = document.createElement("a");
                elem.setAttribute("href", "data:text/plain," + diagramSVG);
                elem.setAttribute("type", "image/svg+xml");
                elem.setAttribute("download", `berd_${rando_key}.svg`);
                elem.style.display = "none";
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);

                //aaand shortly thereafter download the state tree
                setTimeout(() => {
                    let data = stateToString(currentState);
                    console.log(data)
                    let elem = document.createElement("a");
                    elem.setAttribute("href", "data:text/plain," + encodeURIComponent(data));
                    elem.setAttribute("type", "text/plain");
                    elem.setAttribute("download", `berd_${rando_key}.json`);
                    elem.style.display = "none";
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }, 1000)

            /!*})
            .catch((error) => {
                console.log("Error getting SVG", error)
            })*!/
    }*/


    /*useEffect(() => {

        let racing = [inFlightDelay, Promise.resolve(false)]
        Promise.race(racing).then((res) => {
            if (!res) {
                inFlight.abort()
                let ac = new AbortController()
                setInFlight(ac)
            }
        })
        //set up new AbortController so I cancel the fetch if the component re-renders

    }, [inFlight, inFlightDelay])*/

    useEffect(() => {
        clearTimeout(inFlightDelay)
        inFlightAC.abort()
        //let ac = new AbortController()
        inFlightAC = new AbortController()
        //setInFlightAC(ac)
      const func_svg_url = (window.location.hostname === "localhost")?"http://localhost:7071/api/http_berd":"https://berd.azurewebsites.net/api/http_berd";
        if ( Object.keys(components()).length > 0 || Object.keys(ships()).length > 0 ) {
            let payload = JSON.stringify({
                "berd_payload": {
                    "components": components(),
                    "relationships": ships(),
                    "options": options(),
                    "colors": colors()
                }
            });

            localconsole("fetch with: ")
            localconsole(payload)
            //let inFlightTimeout = setTimeout(() => {
            let inFlightTimeout = setTimeout(() => {
                fetch(func_svg_url, {
                    method: "POST",
                    signal: inFlightAC.signal,
                    body: payload,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((data) => {

                        /* font injection... */

                        let p = new DOMParser()
                        let d = p.parseFromString(data, "image/svg+xml")
                        //console.log(d)
                        let svg = d.children[0]
                        let defs = svg.getElementsByTagName("defs")
                        let style = document.createElementNS("http://www.w3.org/2000/svg", 'style');

                        //let existingStyle = svg.getAttribute("style")


                        //insert style into the svg
                        let def0 = defs.item(0)
                        if (def0) {
                            //def0.parentNode.insertBefore(style, defs.item(0))
                            def0.appendChild(style)
                        }

                        style.type = "text/css"

                        //first converted from ttf:
                        //woff2_compress FiraSans-Regular.ttf
                        //base64 FiraSans-Regular.woff2
                        style.innerHTML = `
                        @font-face {
                            font-family: "Fira Sans";
                            src: url(data:font/woff2;base64,${base64_font});
                        }
                    `
                        //svg.setAttribute("style", existingStyle + new_style)

                        //console.log(d)
                        let new_data = new XMLSerializer().serializeToString(svg);

                        /* End of font injection */
                        console.log("updating svg")
                        dispatch(newSVG(encodeURIComponent(new_data)))
                    })
                    .catch((error) => {
                        console.log("Error getting SVG", error)
                    })
                return Promise.resolve(true)
            }, 1)
            //setInFlightDelay(inFlightTimeout)
            inFlightDelay = inFlightTimeout
        } else {
            localconsole("Assembly has no comps or ships to render")
            dispatch(newSVG(""))
        }

        return () => {
            //clean up any in flight requests when component is unloaded
            clearTimeout(inFlightDelay)
            inFlightAC.abort()
        }

    }, [currentProject, isShowAssembly, currentAssembly,
        components(), ships(), options(), colors(),
        dispatch]);



  return (
      <div className={"box content"}>
        <div className="buttons has-addons is-right">
          
          
          <div className={"control"}>
              <button className={"button"}
                onClick={() => dispatch(toggleShowAssembly())}
                aria-label={isShowAssembly ? "show project" : "show assembly"}
                title={isShowAssembly ? "Show Project Diagram" : "Show Assembly Diagram"}
              >
                        <span className={"icon"}>
                            {(isShowAssembly) ? <FontAwesomeIcon icon={faPuzzlePiece}/> :
                                <FontAwesomeIcon icon={faArrowsToDot}/>}
                        </span>
              </button>
          </div>

          <div className={"control"}>
              <button className={"button"}
                onClick={() => props.setFormsVisible(props.isExpanded)}
                aria-label={props.isExpanded ? "shrink diagram" : "expand diagram"}
                title={props.isExpanded ? "Shrink Diagram" : "Expand Diagram"}
              >
                        <span className={"icon"}>
                            {(props.isExpanded) ? <FontAwesomeIcon icon={faCompress}/> :
                                <FontAwesomeIcon icon={faExpand}/>}
                        </span>
              </button>
          </div>
          
        </div>
          {/* Could use style={{mixBlendMode: "multiply"}} here to get rid of white background but currently prefer the white box*/}
          <figure className="image" style={{marginLeft: "auto", marginRight: "auto"}}>
              {(diagramSVG === "") ?
                  <img src="berd_placeholder.svg" alt="Placeholder berd diagram"></img>
                  :
                  <img src={"data:image/svg+xml," + diagramSVG} alt="berd diagram"></img>}
          </figure>


      </div>
  );
}

export default Diagram;
