import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { Assembly, newAssemblyObject } from '../objects/assembly';
import { RootState } from '../store';
import { Comp, CompDictionary } from '../objects/comp';
import { selectProjects } from './systemSlice';
import { localconsole } from '../../App';
import { Ship, ShipDictionary } from '../objects/ship';
import { ProjectBuilder } from '../objects/projectBuilder';
import { Tag } from '../objects/tag';
import { KVProps } from '../objects/types';

//TODO - add rename ability

export interface AssemblieSlice {
    existingAssemblyNames: string[],
    assemblies: Assembly[],
    currentAssembly: string,
}


export const sliceInitial: AssemblieSlice = {
    existingAssemblyNames: [] as string[],
    assemblies: [] as Assembly[],
    currentAssembly: "",
}


//using normalised state shape with Ids in objects instead of arrays as it's quite natural for a database model!
export const assemblySlice = createSlice({
    name: 'assembly',
    initialState: sliceInitial,
    reducers: {
        newAssembly: (state, action: PayloadAction<Assembly>) => {
            let Assembly = action.payload;
            Assembly.version = Assembly.version ?? 1;
            if (!state.existingAssemblyNames.includes(Assembly.name) ) {
                state.existingAssemblyNames.push(Assembly.name);
                state.assemblies.push(Assembly);
            }
            state.currentAssembly = Assembly.name
        },
        addProjectToAssembly: (state, action: PayloadAction<string>) => {
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            if (!a.projects.includes(action.payload)) {
                a.projects.push(action.payload)
            }
        },
        addTagToCurrentAssembly: (state, action: PayloadAction<string>) => {
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            let new_tag = action.payload
            let tag_names = a.tags.map(t => t.name)
            if (!tag_names.includes(new_tag)) {
                let tag: Tag = {
                    name: new_tag,
                    active: false,
                }
                a.tags.push(tag)
            }
        },
        toggleTagInCurrentAssemly:  (state, action: PayloadAction<string>) => {
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            let tag = a.tags.find(t => t.name === action.payload)
            if (tag) {
                tag.active = !tag.active
            }
        },
        removeTagFromAssembly: (state, action: PayloadAction<string>) => {
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            let tag = a.tags.find(t => t.name === action.payload)
            for ( let i = a.tags.length; i > 0; i-- ) {
                if ( a.tags[i-1] === tag ) {
                    a.tags.splice(i-1, 1)
                    break
                }
            }
        },
        toggleUnTagged: (state, action: PayloadAction<string>) => {
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            let option = action.payload
            if (option.toLowerCase().includes("comp")) {
                a.include_untagged_comps = !a.include_untagged_comps
            } else {
                a.include_untagged_ships = !a.include_untagged_ships
            }
        },
        removeProjectFromAssembly: (state, action: PayloadAction<string>) => {
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            
            for ( let i = a.projects.length; i > 0; i-- ) {
                if ( a.projects[i-1] === action.payload ) {
                    a.projects.splice(i-1, 1)
                    break
                }
            }
        },
        renameAssembly: (state, action: PayloadAction<{old_name: string, new_name: string}>) => {
            localconsole("ENTER: systemSlice.reducer.renameAssembly")
            let {old_name, new_name} = action.payload;
            

            if (!state.existingAssemblyNames.includes(new_name) ) {
                let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
                a.name = new_name
                //update the project name before the projects to avoid Effects in Builder using currentProject instead of new one
                state.currentAssembly = new_name
                for ( let i = state.existingAssemblyNames.length; i > 0; i-- ) {
                    if ( state.existingAssemblyNames[i-1] === old_name ) {
                        state.existingAssemblyNames.splice(i-1, 1, new_name)
                        break
                    }
                }
            }
        },
        removeAssembly: (state, action: PayloadAction<string>) => {
            let Assembly = action.payload;
            //console.log(`"Removing ${Assembly}`)
            state.currentAssembly = ""
            for (let i = state.existingAssemblyNames.length; i > 0; i--) {
                if ( state.existingAssemblyNames[i-1] === Assembly ) {
                    state.existingAssemblyNames.splice(i-1, 1)
                }
            }
            for ( let i = state.assemblies.length; i > 0; i-- ) {
                if (state.assemblies[i-1].name === Assembly) {
                    state.assemblies.splice(i-1, 1)
                }
            }
        },
        //changes the selected Assembly, so just the name in the selection
        changeAssembly: (state, action: PayloadAction<string>) => {
            let Assembly = action.payload;
            state.currentAssembly = Assembly
        },
        //modify the contents of a Assembly, that is, the diagram
        updateAssembly: (state, action: PayloadAction<Assembly>) => {
            let Assembly = action.payload;
            state.currentAssembly = Assembly.name
            for ( let i = state.assemblies.length; i > 0; i-- ) {
                if (state.assemblies[i-1].name === Assembly.name) {
                    state.assemblies.splice(i-1, 1, Assembly)
                }
            }
        },
        versionIncrement: (state) => {
            for ( let i = state.assemblies.length; i > 0; i-- ) {
                if (state.assemblies[i-1].name === state.currentAssembly) {
                    state.assemblies.splice(i-1, 1, {
                        ...state.assemblies[i-1],
                        version: (state.assemblies[i-1].version ?? 0) + 1
                    })
                }
            }
        },
        setAssemblyColor: (state, action: PayloadAction<KVProps>) => {
            localconsole("ENTER: assemblySlice.reducer.setAssemblyColor")
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
            let color = action.payload
            
            a.colors[color.key] = color.value
        },
        toggleAssemblyOption: (state, action: PayloadAction<string>) => {

            localconsole("ENTER: assemblySlice.reducer.toggleAssemblyOption")
            let a = state.assemblies.filter((_a) => _a.name == state.currentAssembly)[0]
                        
            let option_name = action.payload
            if (Object.keys(newAssemblyObject.options).includes(option_name)) {
                if (option_name === "Gantt" && window.location.hostname !== "localhost") {
                    //temporarily do not allow Gantt as I have not finished the code
                    console.log("Gantt is disabled until feature is complete")
                } else {
                    a.options[option_name] = !(!!(a.options[option_name]))
                }
            }
        },
    },
})

export const selectAssemblies = (state: RootState) => state.assembly.assemblies
export const selectCurrentAssemblyName = (state: RootState) => state.assembly.currentAssembly
export const selectCurrentAssembly = createSelector(
    [selectAssemblies, selectCurrentAssemblyName],
    (assemblies: Assembly[], currentAssemblyName: string) => {
      return assemblies.find(assembly => assembly.name === currentAssemblyName) ?? newAssemblyObject;
    }
  );
  

export const selectAssemblyComps = createSelector(
    [selectCurrentAssembly, selectProjects],
    (currentAssembly: Assembly, currentProjects: ProjectBuilder[]): CompDictionary => {
    let comps: Comp[] = []
    //update logic
    //Adding a project to an assembly should add all the tags, in disabled state, from that project to the assembly so they can be included or excluded.
    //Elements with no tags should be given the 'untagged' Tag, which can then be toggled as a group in the assembly

    //select logic
    //So, assembly with no projects should have nothing.
    //if a component has an enabled tag and a disabled tag, it should be disabled

    
    
    //if no tags: include based on untagged Tag state of the assembly
    //if tags:    for each tag which is in assembly as disabled, ignore the element
    //            for each tag which is in the assembly as active (and not any disabled), include the element
    //            for each tag which is not in the assembly (its been removed for example), ignore the element
    //let currentAssembly = selectCurrentAssembly(state)
    if (currentAssembly) {
        //get all the projects that are included in this assembly
        let projects = currentProjects.filter(p => currentAssembly.projects.includes(p.name))
    
        //for each project in assembly, get the project Comps, filter using assembly tags
        for (let p of projects) {
            for (let k of Object.keys(p.components)) {
                let comp = p.components[k]
                let decision = false;

                if (comp.tags === undefined || comp.tags.length === 0) {
                    //this is an untagged component, check for the untagged decision in the assembly
                    //if (currentAssembly.include_untagged_comps) {
                        decision = currentAssembly.include_untagged_comps
                    //} else {
                    //    decision = false
                    //}
                } else {

                    //loop throught the assembly tags, adding the comp if required
                    for (let tag of currentAssembly.tags) {
                        if (comp.tags.includes(tag.name)) {
                            //this tag is relevant
                            if (!tag.active) {
                                //this tag is disabled, do not include the comp
                                decision = false;
                                break
                            } else {
                                decision = true
                            }
                        }
                    }
                }
                if (decision){
                    comps.push(comp)
                }
            }
        }

    }
    return comps.reduce((acc, c, i) => {acc[i] = {...c, id: i}; return acc}, {} as CompDictionary)
});


export const selectAssemblyShips = createSelector(
    [selectCurrentAssembly, selectProjects],
    (currentAssembly: Assembly, currentProjects: ProjectBuilder[]): ShipDictionary => {

    let ships: Ship[] = []
    
    if (currentAssembly) {
        //get all the projects that are included in this assembly
        let projects = currentProjects.filter(p => 
            currentAssembly.projects.includes(p.name))
    
        //for each project in assembly, get the project Comps, filter using assembly tags
        for (let p of projects) {
            for (let k of Object.keys(p.ships)) {
                let ship = p.ships[k]
                let decision = false;

                if (ship.tags === undefined || ship.tags.length === 0) {
                    //this is an untagged component, check for the untagged decision in the assembly
                    //if (currentAssembly.include_untagged_ships) {
                        decision = currentAssembly.include_untagged_ships
                    //} else {
                    //    decision = false
                    //}
                } else {
                    //loop throught the assembly tags, adding the comp if required
                    for (let tag of currentAssembly.tags) {
                        if (ship.tags.includes(tag.name)) {
                            //this tag is relevant
                            if (!tag.active) {
                                //this tag is disabled, do not include the comp
                                decision = false;
                                break
                            } else {
                                decision = true
                            }
                        }
                    }
                }
                if (decision){
                    ships.push(ship)
                }
            }
        }

    }
    return ships.reduce((acc, s, i) => {acc[i] = {...s, id: i}; return acc}, {} as ShipDictionary)
});

export const selectExistingAssemblyNames = (state: RootState) => state.assembly.existingAssemblyNames

export const {
    newAssembly,
    removeAssembly,
    changeAssembly,
    updateAssembly,
    versionIncrement,
    renameAssembly,
    addProjectToAssembly,
    removeProjectFromAssembly,
    addTagToCurrentAssembly,
    toggleTagInCurrentAssemly,
    toggleUnTagged,
    setAssemblyColor,
    removeTagFromAssembly,
    toggleAssemblyOption,
} = assemblySlice.actions

export default assemblySlice.reducer