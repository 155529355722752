import { ColorDictionary } from "./types"




//optional name and db_name so the user can specify either
export interface Column {
    id: number,
    name?: string,
    db_name?: string,
}

export enum ColumnFieldsDictionary {
    name,
    db_name
}

//TODO - consider adding newComp const and refactor into comps.ts newItem

//component of the diagram, might be a database table or just an object
//optional name and db_name so the user can specify either
export interface Comp {
    id: number,
    name?: string,
    db_name?: string,
    alias?: string,
    description?: string,
    columns: Column[],
    colors?: ColorDictionary,
    tags: string[],  //arbitrary tags used to group into Assemblies
}

export const newComp: Comp = {
    columns: [],
    id: 0,
    tags: []
}

//allows generic inputs for fields in accordian, columns excluded as rendered specially
export enum CompFieldsDictionary {
    name,
    db_name,
    alias,
    description,
    colors,
}

export interface CompDictionary {
  [key: string]: Comp
}




export function getCompReference(item: Comp): string | undefined {
    if (item.name && item.db_name && item.alias) {
        return item.alias
    }
    if (!item.db_name && !item.alias) {
        return item.name
    }
    if (!item.name && !item.alias) {
        return item.db_name
    }
    if (!item.name && !item.db_name) {
        return item.alias
    }
    if (!item.db_name) {
        //assume name and alias
        return item.alias
    }
    if (!item.alias) {
        //assume name and table
        return item.name
    }
    if (!item.name) {
        //assum table and alias
        return item.alias
    }

    //return (!item.name && item.db_name)?"<<" + (item.db_name??"").toLowerCase() + ">>":item.name
    return item.name
}



