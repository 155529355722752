import { Tag } from "./tag";
import { ColorDictionary, OptionDictionary } from "./types";

export interface Assembly {
    name: string,
    version?: number,
    projects: string[],  //include or exclude projects, based on name. Allows tags to be shared across projects without neccisarily being shared across assemblies, as the assembly can exclude projects
    include_untagged_comps: boolean,
    include_untagged_ships: boolean,
    tags: Tag[],  //included or excluded tags
    options: OptionDictionary,
    colors: ColorDictionary,
}

export const newAssemblyObject: Assembly = {
    name: "",
    projects: [],
    include_untagged_comps: true,
    include_untagged_ships: true,
    tags: [],
    options: {
        "Legend": false,
        "Orthogonal": false,
        "Fields": true,
    } as OptionDictionary,
    colors: {
        "Background": "#8e3d67"
    },
}