//TODO 20241210 - get these from elsewhere. import {Comp, CompFieldsDictionary} from "../state/slices/diagramSlice";
import React from "react";
import { Comp, CompFieldsDictionary } from "../state/objects/comp";


interface CompsInputProps {
    record: Comp,
    field: CompFieldsDictionary,
    setRecord: (record: Comp)=>void,
    activateRecord: (id: number)=>void
}

const CompsInput = (props: CompsInputProps) => {
    let fieldVal: string;
    switch(props.field) {
        case CompFieldsDictionary.name: { fieldVal = props.record.name ?? "" ; break; }
        case CompFieldsDictionary.db_name: { fieldVal = props.record.db_name ?? "" ; break; }
        case CompFieldsDictionary.alias: { fieldVal = props.record.alias ?? "" ; break; }
        case CompFieldsDictionary.description: { fieldVal = props.record.description ?? "" ; break; }
        default: { fieldVal = ""; break; }
    }
    const setFieldVal = (newFieldVal: string) => {
        let newRecord = {
            ...props.record
        }
        switch(props.field) {
            case CompFieldsDictionary.name: { newRecord.name = newFieldVal; break; }
            case CompFieldsDictionary.db_name: { newRecord.db_name = newFieldVal; break; }
            case CompFieldsDictionary.alias: { newRecord.alias = newFieldVal; break; }
            case CompFieldsDictionary.description: { newRecord.description = newFieldVal; break; }
            default: { break; }
        }
        props.setRecord(newRecord)
    }

    return (
        <input className="input"
               type="text"
               //placeholder={props.field}
               //onClick={(e) => e.stopPropagation(); props.setActiveComp(props.id);}
               onChange={(e) => { setFieldVal(e.currentTarget.value) } }
               value={fieldVal}
               onClick={(e) => {
                   //highlight record but don't toggle on Inputs, to deselect the row itself should be clicked
                   props.activateRecord(props.record.id);
                   e.stopPropagation();
               }}
        >
        </input>
    )
}

export default CompsInput